import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../Auth/AuthContext';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useModal } from '../../components/common/Modal/ModalContext';
import { Modal } from 'react-bootstrap';
import ExplorerItem from './ExplorerItem';
import { useTranslation } from '../../components/common/LanguageProvider';
function Explorer(props) {
    const { t } = useTranslation();
    const { fid, uid, tid } = useParams();
    const { hashedId, userData } = useAuth();
    const { showModal } = useModal();


    const checkedItems = props.checkedItems;
    const handleCheckboxChange = props.handleCheckboxChange;

    const navigate = useNavigate();
    const [filesToShow, setFilesToShow] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const isFile = props.isFile || false;
    const filesPerPage = isFile ? 10 : 5;
    const [showProcessingModal, setShowProcessingModal] = useState(false);
    const [processingRecordingLoading, setProcessingRecordingLoading] = useState(true);
    const [loading, setLoading] = useState(false);


    const fetchFiles = props.fetchFiles;
    const filesLength = props.filesLength;
    const allFiles = props.allFiles;
    const isExplorerLoading = props.isExplorerLoading;

    var startIndex = 0;
    var endIndex = 0;


    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const onProcessingFileClicked = async (tid) => {
        setShowProcessingModal(true);
        const res = await fetchFiles();
        if (res) {
            // check whether the file is still processing
            if (allFiles.filter(file => file?.SK?.S === "#AmigotorFile#" + tid)[0]?.TStatus?.S === "Processing" || allFiles.filter(file => file?.SK?.S === "#AmigotorFile#" + tid)[0]?.TStatus?.S === "in_call_recording") {
                setProcessingRecordingLoading(false);
            } else {
                setProcessingRecordingLoading(false);
                setShowProcessingModal(false);
                navigate(`/meeting/${tid}`);
            }
        }
    }

    useEffect(() => {
        console.log(hashedId);
        console.log("in use effect")
        if (hashedId || tid || fid) {
            fetchFiles();
        }

    }, [hashedId]);

    useEffect(() => {
        // Calculate the indexes of files to be displayed on the current page
        startIndex = (currentPage - 1) * filesPerPage;
        endIndex = startIndex + filesPerPage;

        // Update the files to show based on the new indexes
        setFilesToShow(allFiles.slice(startIndex, endIndex));

    }, [allFiles, currentPage]);
    useEffect(() => {
        console.log("Checked Items Updated:", checkedItems[0]);
    }, [checkedItems]);
    useEffect(() => {
        if (filesToShow.length > 0) {
            for (let file of filesToShow) {
                let date_flag = false;
                let myDate = new Date(parseInt(file?.Date?.N)) || Date.now(); // assuming file.Date.N is a Unix timestamp in milliseconds
                let currentDate = Date.now();
                let diffInMs = currentDate - myDate.getTime();

                if (diffInMs < (1000 * 60 * 60 * 24)) {
                    date_flag = true;
                }

                let status = file?.TStatus?.S || "Completed";
                const delayInMilliseconds = 120000;

                if (status === "Processing" && date_flag) {
                    setTimeout(fetchFiles, delayInMilliseconds);
                    break; // This will exit the loop if the conditions are met
                }
            }
        }
    }, [filesToShow]);

    return (
        <>
            <>
                <Modal show={showProcessingModal} onHide={() => setShowProcessingModal(false)} centered>
                    <Modal.Body >
                        <button type="button" class="btn-close" onClick={() => setShowProcessingModal(false)} aria-label="Close"></button>
                        <div class="text-center">
                            <h2 class="mini-title text-center mb-4">{t("File in progress")}</h2>
                            <p class="ft-18-title mb-4">{t("This file is still in progress. It will take a few seconds to complete the loading process.")}</p>
                        </div>
                        <div class="text-center">
                            {processingRecordingLoading ? (
                                <div className="spinner-border text-primary" role="status"> </div>
                            ) : (
                                <></>)}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        
            <span className='ft-18-title mb-2 d-flex justify-content-center text-center'  >{t("Files")} </span>
        

            <div className='row'>
                <div className="col-12">
                    <div className={`${isFile ? "card card-padding" : ""}`}>
                        <div className='table-responsive'>
                            {isExplorerLoading ? (
                                 <>
                                 <table className='table'>
                                     <tbody>

                                         {Array.from({ length: isFile ? 10 : 5 }).map((_, index) => (
                                             <tr key={index} style={{ height: "51.07px" }}>
                                                 <Skeleton height={30} style={{ borderRadius: '10px', marginTop: "21.07px" }} />

                                             </tr>
                                         ))}
                                     </tbody>
                                 </table>
                             </>
                            ) : (
                                filesToShow.length === 0 ? (
                                    <div className='d-flex justify-content-center text-center' >
                                        <div className='text-center' style={!isMobileDevice() ? { marginTop: '100px', marginBottom: '100px' } : null}>
                                            <p>{t("Please upload a file")}</p>

                                        </div>
                                    </div>
                                ) : (
                                    <table className='table'>
                                        <tbody>
                                            {filesToShow.map((file, index) => (
                                                <ExplorerItem
                                                    key={file?.SK?.S}
                                                    file={file}
                                                    index={index + ((currentPage - 1) * filesPerPage)}
                                                    isChecked={checkedItems[file?.SK?.S] || checkedItems[file?.OrderID?.S] || false}
                                                    onCheckboxChange={handleCheckboxChange}
                                                    onProcessing={fetchFiles}
                                                    onProcessingFileClicked={onProcessingFileClicked}
                                                    sendOid={props.sendOid}
                                                />
                                            ))}
                                            {Array.from({ length: filesPerPage - filesToShow.length }).map((_, index) => (
                                                <tr key={index} style={{ height: "51.07px" }}>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )
                            )}
                        </div>

                        <nav aria-label="..." className={`${filesToShow.length === 0 ? 'd-none' : ''}`} >
                            <ul className='pagination pagination-sm justify-content-end'>
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <Link className="page-link" to="#" tabIndex="-1" aria-disabled="true" onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>
                                        <span className="fa fa-angle-left"></span>
                                    </Link>
                                </li>

                                {/* Show previous page if it's not the first page */}
                                {currentPage > 1 && (
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage - 1)}>
                                            {currentPage - 1}
                                        </Link>
                                    </li>
                                )}

                                {/* Current Page */}
                                <li className="page-item active">
                                    <Link className="page-link" to="#" onClick={(e) => e.preventDefault()}>
                                        {currentPage}
                                    </Link>
                                </li>

                                {/* Show next page if it's not the last page */}
                                {currentPage < Math.ceil(filesLength / filesPerPage) && (
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage + 1)}>
                                            {currentPage + 1}
                                        </Link>
                                    </li>
                                )}

                                <li className={`page-item ${currentPage === Math.ceil(filesLength / filesPerPage) ? 'disabled' : ''}`}>
                                    <Link className="page-link" to="#" onClick={() => setCurrentPage(Math.min(currentPage + 1, Math.ceil(filesLength / filesPerPage)))}>
                                        <span className="fa fa-angle-right"></span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Explorer;