import React, { useEffect, useState } from 'react';
import { useApiData } from '../hooks/useApiData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Files from './Files/Files';
import FilesContainer from './FilesContainer';
import { useModal } from '../components/common/Modal/ModalContext';
import { useAuth } from '../Auth/AuthContext';
import { fetchData, fetchDataPost } from '../hooks/apiUtils';
import { showToast } from '../components/common/toast';
import renameSvg from "../assets/img/icon/rename.svg"
import deleteSvg from "../assets/img/icon/delete.svg"
import { useTranslation } from '../components/common/LanguageProvider';
import ChatBot from "../assets/img/files/tab-chatbot.svg";

function Folder({ }) {
    const { t } = useTranslation();
    const { fid, uid } = useParams();
    const { showModal, hideModal } = useModal();
    const { hashedId, userData } = useAuth();
    const [folderName, setFolderName] = useState('');
    const navigate = useNavigate();

    const fetchFolders = async () => {
        let tempId = hashedId;
        const data = await fetchData("https://i3w4h1tgb5.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFolders?cid=" + tempId);
        if (fid !== undefined) {
            data.forEach(element => {
                if (element.SK.S.replace("User#", "") === fid) {
                    setFolderName(element.Fname.S)
                }
            });
        }
    };
    useEffect(() => {
        console.log(hashedId);
        console.log("in use effect")
        if (hashedId || fid) {
            fetchFolders();
        }

    }, [hashedId]);
    const onRename = async (fid, uid, name) => {
        console.log(name, fid, uid);

        const response = await fetchDataPost("https://xc8v5q5vc2.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFolder",
            { "tid": fid, "Tname": name, "cid": uid });
        setFolderName(name);
        showToast(t("Folder renamed"));
    };
    const onDelete = async (fid, uid) => {
        console.log(fid, uid);

        const response = await fetchDataPost("https://5ex4m1gaw1.execute-api.eu-central-1.amazonaws.com/default/AA-API-DeleteFolder?cid=" + uid + "&tid=" + fid);
        showToast(t("Folder deleted"));
        navigate("/folders")
    };

    const start_session = async () => {
		try {
            var url = process.env.REACT_APP_MODE === 'development' ? "https://kaj6gbm9fd.execute-api.eu-central-1.amazonaws.com/default/Amigotor-StartSession" : "https://ne6bu8gjzj.execute-api.eu-central-1.amazonaws.com/default/Amigotor-StartSession"

			const response = await fetch(url,
				{
					method: 'POST',
					body: JSON.stringify(
						{
							"uid": hashedId,
							"fid": fid
						})
				}
			);

			if (!response.ok) {
				throw new Error('HTTP status ' + response.status);
			} else {
				const data = await response.json();
                hideModal();
				navigate(`/chat/${data.orderid}`);
				return;
			}

		} catch (error) {
			console.error('Error:', error);
		}

	};

    return (
        <>
            <>
                <h2 class="card-title w-100"><Link to="/folders" class="hover-underline">{t("Folders")}</Link> / {folderName}
                    <div class="action-icon-list d-sm-inline-flex d-flex justify-content-end">
                    <Link onClick={() => showModal('START-FOLDER-SESSION', { onStartSession: start_session })}>
                            <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Start Chat")}>
                                <img src={ChatBot} class="img-fluid" alt="img" />
                            </span>
                        </Link>
                        <Link onClick={() => showModal('RENAME', { tid: fid, uid: hashedId, name: folderName }, { onRename })}>
                            <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Rename")}>
                                <img src={renameSvg} class="img-fluid" alt="img" />
                            </span>
                        </Link>
                        <Link onClick={() => showModal('DELETE-FOLDER', { fid: fid, uid: hashedId }, { onDeleteFolder: onDelete })}>
                            <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={("Delete")}>
                                <img src={deleteSvg} class="img-fluid" alt="img" />
                            </span>
                        </Link>
                    </div>
                </h2>
            </>
            <FilesContainer fid={fid} uid={uid} isFile={true} isFolder={true} />

        </>
    );
}

export default Folder;
