import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../Auth/AuthContext";
import { Link } from "react-router-dom";
import { useTranslation } from "../../components/common/LanguageProvider";
import { Modal } from "react-bootstrap";
import Explorer from "../Explorer/Explorer";
import { fetchData } from "../../hooks/apiUtils";
function SourceFilesDropdown(props) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { hashedId } = useAuth();
    const dropdownRef = useRef(null);
    const activeRef = useRef(null)
    const files = props.files || [];
    const [inputValue, setInputValue] = useState("");
    const [filteredDocs, setFilteredDocs] = useState(files);
    const [showAddFileModal, setShowAddFileModal] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [allFiles, setAllFiles] = useState([]);
    const amigotorOid = props.amigotorOid;
    const [addFileInProgress, setAddFileInProgress] = useState(false);

    const [filesLength, setFilesLength] = useState(0);
    const [isExplorerLoading, setIsExplorerLoading] = useState(true);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const handleKeyDown = (e) => {
        setInputValue(e.target.value)
    };

    const formatName = (name) => {
        return name.length > 22 ? name.slice(0, 22) + "..." : name;
    }
    const fetchFiles = async () => {
        setIsExplorerLoading(true); // Set loading to true at the beginning of data fetching

        var url = "https://4g55e4e6dd.execute-api.eu-central-1.amazonaws.com/default/Amigotor-ListFiles?uid=" + hashedId + "&app=Amigotor";

        const data = await fetchData(url);
        const files = data.files ?? data;
        files.sort((a, b) => {
            return parseInt(b.Date.N) - parseInt(a.Date.N);
        });
        setAllFiles(files);
        setFilesLength(files.length);
        setIsExplorerLoading(false); // Set loading to false once data fetching is complete
    }
    const handleCheckboxChange = (fileId, isChecked) => {
        setCheckedItems(prevState => {
            const tempArray = { ...prevState };

            tempArray[fileId] = isChecked;

            const checkedIds = Object.keys(tempArray).filter(id => tempArray[id]);


            return tempArray;
        });
    };

    const onAddClick = async () => {
        setAddFileInProgress(true);
        const files = Object.keys(checkedItems).filter(id => checkedItems[id]);
        try {
            for (let i = 0; i < files.length; i++) {

                const res = await fetch(`https://655j0fqnlb.execute-api.eu-central-1.amazonaws.com/default/Amigotor-AddFileToSession?soid=${amigotorOid}&foid=${files[i]}`);

            }
        }
        catch (error) {
            console.error('Error adding file to session:', error);
        }
        setAddFileInProgress(false);
        props.fetchChatData();
        setShowAddFileModal(false);
        setCheckedItems({});
    }

    return (
        <>
            <Modal show={showAddFileModal} onHide={() => setShowAddFileModal(false)} centered size={'xl'}>
                <Modal.Body className='p-0' >
                    <button type="button" class="btn-close" onClick={() => setShowAddFileModal(false)} aria-label="Close"></button>

                    <div className='row gx-0'>

                        <div className='col-lg-12 order-lg-1 order-2'>
                            <div className='modal-body-padding'>

                                <div className='card card-padding  card-margin'>
                                    <Explorer checkedItems={checkedItems} handleCheckboxChange={handleCheckboxChange} isExplorerLoading={isExplorerLoading} allFiles={allFiles} filesLength={filesLength} fetchFiles={fetchFiles} sendOid={true} />
                                </div>
                                <div className='d-flex justify-content-end mt-3'>
                                    <button type="button" class="btn theme-btn" onClick={() => {
                                        onAddClick()
                                    }}
                                        disabled={addFileInProgress || Object.keys(checkedItems).filter(id => checkedItems[id]).length < 1}
                                    >
                                        {addFileInProgress ? <div className="spinner-border text-primary white-color" role="status" style={{ height: '20px', width: '20px' }}>

                                        </div> :
                                            t("Add")}</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
            <div class='d-flex align-items-center'>
                <div
                    ref={dropdownRef}
                    className={`selectBox ${isOpen ? "show" : ""}`}
                >
                    <div className="form-control round-input border-input selectBox__value me-3" tabIndex="0" onClick={() => setIsOpen(!isOpen)}>

                        {formatName(files[0].name)}
                        {files.length > 1 && ` (+${files.length - 1})`}
                    </div>
                    {isOpen && (
                        <div className="dropdown-menu">
                            <div className="language-group">

                                {Object.entries(files).map(([code, fil], index) => (
                                    <span
                                        ref={index === 0 ? activeRef : null}
                                        className={`dropdown-item ${index === 0 ? "active" : ""} d-flex justify-content-between`}
                                        value={code}
                                    >
                                        {formatName(fil.name)}
                                        {files.length > 1 &&
                                            <svg
                                                onClick={() => props.onDeleteSouceFile(fil)}
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3 pointer" viewBox="0 0 16 16">
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                            </svg>}
                                    </span>
                                ))}
                            </div>

                        </div>
                    )}
                </div>
                <span
                    class="file-action-list "
                    style={{
                        height: '32px',
                        width: '32px',
                        marginLeft: '10px',
                        marginTop: '10px',
                    }}
                    onClick={() => setShowAddFileModal(true)}
                >
                    <span className="hover-span">
                        {t("Add New File")}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-plus-lg" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                    </svg>
                </span>
            </div>
        </>
    );
}

export default SourceFilesDropdown;

