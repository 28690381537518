import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../Auth/AuthContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import googleIcon from '../assets/img/icon/google-icon.png';
import emailSvg from '../assets/img/icon/email.svg';
import heroImg0 from '../assets/img/hero-img0.png';
import heroImg1 from '../assets/img/hero-img1.png';
import heroImg2 from '../assets/img/hero-img2.png';
import heroImg3 from '../assets/img/hero-img3.png';
import heroImg4 from '../assets/img/hero-img4.png';
import heroImg5 from '../assets/img/hero-img5.png';
import logo from '../assets/img/logo_nobuffer.png';
import heroBg0 from '../assets/img/hero-bg0.png'
import heroBg1 from '../assets/img/hero-bg1.jpg';
import heroBg2 from '../assets/img/hero-bg2.jpg';
import heroBg3 from '../assets/img/hero-bg3.jpg';
import heroBg4 from '../assets/img/hero-bg4.png';
import heroBg5 from '../assets/img/hero-bg5.png';
import { showToast } from '../components/common/toast';
import countryLanguage from 'country-language';
import { useModal } from "../components/common/Modal/ModalContext";
import { loadLanguageTranslations, useTranslation } from '../components/common/LanguageProvider';
function LoginComponent() {
    const { t } = useTranslation();

    const { login, signInWithToken, sendResetPasswordMail, googleLogin } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // To retrieve a specific query parameter:
    const uid = queryParams.get('uid');
    const redirect = queryParams.get('redirect');
    const lang = queryParams.get('lang');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isEmail, setIsEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [isRegister, setIsRegister] = useState(false);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^.{6,}$/;
    const [language, setLanguage] = useState('');
    const [country, setCountry] = useState('');
    const rememberRef = useRef();
    const [isRemember, setIsRemember] = useState(true);
    const { showModal } = useModal();
    async function handleLogin() {
        try {
            setIsLoading(true);
            setError('');
            if (!emailRegex.test(email)) {
                setIsLoading(false)
                setError("invalid-email")
                showToast(t("Invalid email type. Please check your email."), {type: "error", position:"top-center"});
                return;
            }
            if (!passwordRegex.test(password)) {
                setIsLoading(false)
                showToast(t("Short password. The password must be 6 characters long or more."), {type: "error", position:"top-center"});
                return;
            }
            const response = await login(email, password);
            console.log(response)
            
            sessionStorage.setItem("remember", isRemember);
            localStorage.setItem("remember", isRemember);
            console.log(isRemember)
            console.log("remember")
            navigate('/dashboard');

            // Do something after successful login, like redirecting to another page
        } catch (error) {
            setIsLoading(false)
            console.error("Failed to login:", error);
            console.log(error.code)
            if (error.code === "auth/user-not-found") {
                showToast(t("User not found. Please create an account."), {type: "error", position:"top-center"});
            }else if(error.code === "auth/invalid-email"){
                showToast(t("Invalid email type. Please check your email."), {type: "error", position:"top-center"});
            }else if(error.code === "auth/wrong-password"){
                showToast(t("Wrong password. Please check your password."), {type: "error", position:"top-center"});
            }else{
                showToast(t("An error occured. Please try again."), {type: "error", position:"top-center"});
            }
            // Handle login errors, for example, show a message to the user
        }
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            handleLogin();
        }
    }

    useEffect(() => {
        if(!localStorage.getItem("dashboard-language"))
            findLanguage();
        if(lang){
            localStorage.setItem("dashboard-language", lang);
            localStorage.setItem("transcript-language", lang);
            setLanguage(lang);
            setCountry(countryLanguage.getCountry(lang).iso639_1);
            loadLanguageTranslations(lang); // Load translations once when the app starts
            window.location.href = "/signIn";

        }
    }, []);
    const findLanguage = async () => {
        try {
          const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
          const [iso639_1, countryCode] = browserLanguage.split("-");
      
          setLanguage(iso639_1);
          localStorage.setItem("dashboard-language", iso639_1);
          localStorage.setItem("dashboard-country", countryCode || 'US');
          localStorage.setItem("transcript-language", browserLanguage);
       
          
          window.location.reload();
      
        } catch (error) {
          console.error('Error detecting browser language:', error);
        }
      };
      
    useEffect(() => {
        if(uid){
            setIsLoading(true);
            automateLogin(uid);
        }else{
            setIsLoading(false);
        }
    }, [uid]);
    const automateLogin = async(uid) => {
        setIsLoading(true);
        const response = await fetch("https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" + uid);
        
        if (response.status === 200) {
            const data = await response.json();
            console.log(data);
            console.log(data["customToken"]); // Here you should see your token or any other data sent from Lambda
            const token = data["customToken"];
            await signInWithToken(token);
            setIsLoading(false);
            console.log("keke")
            if (redirect) {
                
                console.log(redirect)
                navigate(redirect);
                
            } else {
                navigate('/dashboard');
            }
            
        } else {
            console.log('Error:', response.status, response.statusText);
        }
        
        console.log("kek");
    };

    const handleRemember = () => {
        setIsRemember(isRemember => !isRemember);
    };

    const onResetPassword = async(mail) => {
        setIsLoading(true);
        await sendResetPasswordMail(mail);
        showToast(t("Password reset mail has been sent..."), {type: "success", position:"top-center"});

        setIsLoading(false);
    };
    const googleSignIn = async () => {
        const response = await googleLogin();
        navigate('/dashboard');

    };
   
    return (
        <>
            <div className='login-page'>
                <div class="row gx-0 align-items-center">
                <div class="col-lg-5">
                    <div class="login-box-height">
                        <div class="login-box">
                        <div className={`d-flex justify-content-center text-center m-2 ${isLoading ? "" : "d-none"}`}>
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            <div class="login-btn-sec text-center">
                                <img src={logo} class="img-fluid" width="80%" alt="img"/>
                                <div class="mt-xl-5 mt-4">
                                    <button onClick={googleSignIn} class="btn theme-btn w-100 white-border-btn mb-3"><span class="btn-icon"><img src={googleIcon} class="img-fluid" alt="img"/></span>{t("Sign In with Google")}</button>
                                    <button class="btn theme-btn w-100"style={{ display: isEmail ? "none" : "" }} onClick={()=> setIsEmail(true)} ><span class="btn-icon"><img src={emailSvg} class="img-fluid" alt="img"/></span>{t("Sign In with E-mail")}</button>
                                </div>
                            </div>
                            <br class="d-lg-block d-none"></br>
                            <div  class="sign-form mt-2" style={{ display: !isEmail ? "" : "block" }}>
                                <div class="form-group input-icon-div mb-0">
                                    <label>{t("Email")}</label>
                                    <input type="email" class="form-control" name="" placeholder={t("Email")} value={email} onChange={(e)=>setEmail(e.target.value)}/>
                                    <span class="input-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                                        </svg>
                                    </span>
                                </div>
                                <p className='text-xs mt-0 text-danger'>{error === "invalid-email" ? "Invalid mail, please check your email!" : ""}</p>

                                <div class="form-group input-icon-div mb-3">
                                    <label>{t("Password")}</label>
                                    <input type="password" class="form-control" name="" placeholder={t("Password")} value={password} maxLength="100" onChange={(e)=> setPassword(e.target.value)} onKeyDown={handleKeyDown}/>
                                    <span class="input-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-lock" viewBox="0 0 16 16">
                                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
                                        </svg>
                                    </span>
                                </div>
                                <div class="d-flex justify-content-between mb-4">
                                    <div class="checkbox custom ft-14">
                                        <label><input type="checkbox" ref={rememberRef} onChange={handleRemember}  checked={isRemember}/>{t("Remember me")}</label>
                                    </div>
                                    <Link  class="login-theme-color ft-14 hover-underline" onClick={()=> {
                                        showModal('RESET-PASSWORD',{ email}, {onResetPassword});
                                        console.log("forgot password")
                                    }}>{t("Forgot Password?")}</Link>
                                </div>
                                <div className={`d-flex justify-content-center text-center m-2 ${isLoading ? "" : "d-none"}`}>
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <button  class="btn theme-btn w-100" onClick={handleLogin} >{t("Sign In")}</button>
                            </div>
                            <p class="text-center mt-xl-5 mt-4">{t("Don't have an account yet?")} <Link class="login-theme-color hover-underline" to="/register">&nbsp;{t("Join Us")}</Link></p>


                        </div>
                    </div>
                    
                    <div class="col text-center">
                                <small class="info-terms">
                                    <span>{t("By using Amigotor you agree to the")} </span>
                                    <a style={{color:"#009688"}} href="https://transkriptor.com/terms-and-conditions/" target="_blank">{t("Terms of Service")}</a>
                                    <span> {t("and")} </span>
                                    <a style={{color:"#009688"}} href="https://transkriptor.com/privacy-policy/" target="_blank">{t("Privacy Policy")}</a>
                                </small>
                            </div>
                </div>
                <div class="col-lg-7 d-lg-block d-none">
                    <div class="login-img-div white-color">
                        <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-bs-ride="carousel">
                            <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 0"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" class="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 4"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 5"></button>
                            </div>
                            <div class="carousel-inner">
                            <div class="carousel-item active">
                                    <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg0})` }}>
                                        <div>
                                            <img src={heroImg0} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">{t("Talk to Files")}</h2>
                                                <p>{t("Transform Your Files into an Artifical Intelligence Guide")}</p>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item ">
                                    <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg1})` }}>
                                        <div>
                                            <img src={heroImg1} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">{t("Convert Audio To Text")}</h2>
                                                <p>{t("Automatically transcribe your meetings, interviews, lectures, and other conversations.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg2})` }}>

                                        <div>
                                            <img src={heroImg2} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">{t("Convert Text To Speech")}</h2>
                                                <p>{t("Automatically convert text to speech (voice) with SPEAKTOR's artificial intelligence text reader.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg3})` }}>
                                        <div>
                                            <img src={heroImg3} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">{t("AI Content Writer")}</h2>
                                                <p>{t("Automatically generate different formats of text with Eskritor's artificial intelligence (AI) content writer.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg4})` }}>
                                        <div>
                                            <img src={heroImg4} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">{t("Smart meeting recorder")}</h2>
                                                <p>{t("Automate, transcribe, organize, and share meeting recordings.")}</p>
                                                <br/>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg5})` }}>
                                        <div>
                                            <img src={heroImg5} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">{t("Screen Recoder")}</h2>
                                                <p>{t("Record, Save, Transcribe and Share Instantly")}</p>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}

export default LoginComponent;
