import React, { memo, useEffect, useRef, useState } from "react";
import ChatBotWhite from "../../assets/img/files/tab-chatbot-white.svg";
import Logo from "../../assets/img/icon/logo-icon.png";
import { useAuth } from "../../Auth/AuthContext";
import { fetchData, fetchDataPost } from "../../hooks/apiUtils";
import OutsideClickHandler from "react-outside-click-handler";
import { showToast } from "../../components/common/toast";
import { useNavigate } from "react-router-dom";
import { set } from "react-hook-form";
import { useTranslation } from "../../components/common/LanguageProvider";
import SourceFilesDropdown from "../ChatView/SourceFilesDropdown";
import { marked } from "marked";

function AiChat({
  checkedItems,
  amigotorOid,
  setAmigotorOid,
  sessionName,
  setSessionName,
  singleView,
  setCheckedItems,
  setDate,
  isAnswerLoading,
  setIsAnswerLoading,
  setTranskriptorOrderId,
  setTranskriptorSK,
  setOrderPk,
  setRecorditorSK,
  setMeetingtorSk,
}) {
  const { t } = useTranslation();
  const { hashedId, userData } = useAuth();

  const [chatContent, setChatContent] = useState([]);
  const [question, setQuestion] = useState("");
  const [customPrompt, setCustomPrompt] = useState("");
  const [savedCustomPrompt, setSavedCustomPrompt] = useState("");
  const scrollRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const navigate = useNavigate();
  const [internalSuggestedQuestions, setInternalSuggestedQuestions] = useState(
    []
  );
  const [questionIndex, setQuestionIndex] = useState(-1);
  const [internetEnabled, setInternetEnabled] = useState(
    localStorage.getItem("internetEnabled") === "true" ? true : false
  );
  const stopStreamRef = useRef(false);
  const [isModeDropdownOpen, setIsModeDropdownOpen] = useState(false);
  const modeRef = useRef(null);
  const sourceRef = useRef(null);
  const memoryRef = useRef(null);
  const [mode, setMode] = useState("general");
  const [source, setSource] = useState(
    localStorage.getItem("internetEnabled") === "true" ? "internet" : "my-file"
  );
  const [memory, setMemory] = useState("disabled");
  const [isSourceDropdownOpen, setIsSourceDropdownOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const modes = {
    general: t("General"),
    custom: t("Custom"),
  };

  const sources = {
    "my-file": "My Files",
    internet: "Internet",
  };

  const handleSelect = (value, type) => {
    if (type === "mode") {
      setMode(value);
      setIsModeDropdownOpen(false);
    } else if (type === "source") {
      if (value !== source) {
        handleToggleChange();
      }
      setSource(value);
      setIsSourceDropdownOpen(false);
    }
  };
  const handleToggleChange = () => {
    setInternetEnabled(!internetEnabled);
    localStorage.setItem("internetEnabled", !internetEnabled);
  };

  const start_session = async () => {
    setCheckedItems({});
    setInternalSuggestedQuestions([]);
    setIsLoading(true);
    try {
      const files = Object.keys(checkedItems).filter((id) => checkedItems[id]);
      var url =
        process.env.REACT_APP_MODE === "development"
          ? "https://kaj6gbm9fd.execute-api.eu-central-1.amazonaws.com/default/Amigotor-StartSession"
          : "https://ne6bu8gjzj.execute-api.eu-central-1.amazonaws.com/default/Amigotor-StartSession";

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          uid: hashedId,
          files: files,
        }),
      });

      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      } else {
        const data = await response.json();
        setAmigotorOid(data.orderid);
        setSessionName(data.title);

        if (isMobileDashboardView()) {
          navigate(`/chat/${data.orderid}`);
          return;
        }
        if (data.questions.questions) {
          setInternalSuggestedQuestions(data.questions.questions);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setIsLoading(false);
  };

  const onDeleteSouceFile = (file) => {
    const newFiles = files.filter((f) => f !== file);
    setFiles(newFiles);
    fetch(
      `https://e97zokpqq8.execute-api.eu-central-1.amazonaws.com/default/Amigotor-DeleteFileFromSession?soid=${amigotorOid}&fsk=${file.sk.replace(
        "#AmigotorFile#",
        ""
      )}`
    );
  };

  const isMobileDashboardView = () => {
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) && !singleView
    );
  };

  const saveCustomPrompt = async () => {
    setSavedCustomPrompt(customPrompt);
    const res = await fetch(
      `https://43xo7cbkwi.execute-api.eu-central-1.amazonaws.com/default/Amigotor-AddCustomInstructions?soid=${amigotorOid}`,
      {
        method: "POST",
        body: JSON.stringify({ prompt: customPrompt }),
      }
    );
  };

  useEffect(() => {
    scrollToBottom();
  }, [isAnswerLoading]);

  const get_chat = async () => {
    setIsLoading(true);
    setChatContent([]);
    setInternalSuggestedQuestions([]);
    setQuestionIndex(-1);
    if (amigotorOid) {
      let url =
        "https://05ij0k1nni.execute-api.eu-central-1.amazonaws.com/default/Amigotor-RetrieveChatAzure?";
      if (amigotorOid === hashedId) {
        url += "uid=" + amigotorOid;
      } else {
        url += "oid=" + amigotorOid;
      }
      fetchDataPost(url)
        .then((response) => response.json())
        .then((data) => {
          console.log("maner");
          console.log(data);

          if (data.responses) {
            if (
              data.responses.length > 0 &&
              data.responses[data.responses.length - 1].content ===
                "N o   c o n t e n t"
            ) {
              setTimeout(() => {
                get_chat();
              }, 2000);
              return;
            }

            if (
              data.responses.length > 0 &&
              (data.responses[data.responses.length - 1].internet === true ||
                data.responses[data.responses.length - 1].internet === false)
            ) {
              setInternetEnabled(
                data.responses[data.responses.length - 1].internet
              );
            }

            const chat = data.responses.map((response) => {
              if (response.role === "assistant") {
                const { answer, related_questions } = extractContent(
                  response.content
                );
                //   let content = response.content.replace(/'/g, '"').replace(/\n/g, '');
                //    let json = JSON.parse(content);
                return {
                  role: response.role,
                  content: answer,
                  references: response.references,
                };
              }
              return {
                role: response.role,
                content: response.content.replace(/【.*?†source】/g, ""),
              };
            });
            setChatContent(chat);
          }
          if (data.suggested_questions) {
            setInternalSuggestedQuestions(data.suggested_questions);
          }
          if (
            data.session?.custom_prompt &&
            data.session?.custom_prompt !== ""
          ) {
            setSavedCustomPrompt(data.session.custom_prompt);
            setCustomPrompt(data.session.custom_prompt);
            setMode("custom");
          }
          if (setTranskriptorSK && data.session?.TranskriptorSK) {
            setTranskriptorSK(
              data.session.TranskriptorSK.replace("#Transkription#", "")
            );
          }
          if (setRecorditorSK)
            setRecorditorSK(
              data.session?.RecorditorSK?.replace("#Recorditor#", "")
            );
          if (setMeetingtorSk)
            setMeetingtorSk(
              data.session?.MeetingtorSK?.replace("#Meetingtor#", "")
            );
          if (setTranskriptorOrderId && data.session?.TranskriptorOrderId) {
            setTranskriptorOrderId(data.session.TranskriptorOrderId);
          }
          if (setOrderPk && data.session?.PK) {
            setOrderPk(data.session.PK.replace("User#", ""));
          }

          if (data.session?.TempName) {
            setSessionName(data.session.TempName);
          }
          if (data.session?.merged_files && setFiles) {
            let files = [];
            for (let i = 0; i < data.session.merged_files.length; i++) {
              let fileMap = {
                name: data.session.merged_files[i],
                sk: data.session.keys[i],
              };
              files.push(fileMap);
            }
            setFiles(files);
          }

          if (setDate && data.session?.Date) {
            setDate(convertMsToFormattedDate(data.session.Date));
          }

          console.log(data.responses);
          scrollToBottom();
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!amigotorOid && hashedId) {
      setAmigotorOid(hashedId);
      setSessionName(t("Talk With All Files"));
      setIsLoading(false);
    }
  }, [hashedId]);

  function convertMsToFormattedDate(ms) {
    ms = parseInt(ms);
    const date = new Date(ms);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns a 0-based value
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  }
  useEffect(() => {
    if (amigotorOid && hashedId) {
      if (isAnswerLoading || waitingResponse) {
        stopStreamRef.current = true;
      }
      get_chat();
    }
  }, [amigotorOid, hashedId]);

  const generate_answer_stream = async (suggestedQuestion) => {
    if (isAnswerLoading) {
      return;
    }
    const query = suggestedQuestion || question;
    if (query.length > 0) {
      setQuestionIndex(-1);
      setIsAnswerLoading(true);
      setWaitingResponse(true);
      var temp_content = [...chatContent, { role: "user", content: query }];
      setChatContent(temp_content);
      setQuestion("");

      setInternalSuggestedQuestions([]);

      const uri =
        "wss://wddat2ivpe.execute-api.eu-central-1.amazonaws.com/production";
      const websocket = new WebSocket(uri);

      let request_data = {
        action: "sendMessage",

        query: query,
        internet: internetEnabled,
      };
      request_data.uid = hashedId;
      if (amigotorOid === hashedId) {
        request_data.session_specific = false;
      } else {
        request_data.oid = amigotorOid;
        request_data.session_specific = true;
      }

      let content = "";
      let questions = [];

      websocket.onopen = () => {
        console.log("WebSocket connection established");
        websocket.send(JSON.stringify(request_data));
        console.log("> Request sent:", request_data);
      };

      websocket.onmessage = (event) => {
        const response = JSON.parse(event.data);
        console.log(response);

        if (response.status_code) {
          if (response.status_code !== 200) {
            showToast("Unknown error occured, please contact support", {
              type: "error",
            });
            setIsAnswerLoading(false);
            setWaitingResponse(false);
            return;
          }
        }

        if (response.response) {
          setWaitingResponse(false);
          content = response.response;
          content = content.replace(/【.*?†source】/g, "");
          setChatContent((prevContent) => {
            let updatedContent = [...prevContent];
            if (
              updatedContent[updatedContent.length - 1].role !== "assistant"
            ) {
              updatedContent.push({ role: "assistant", content: content });
            } else {
              updatedContent[updatedContent.length - 1].content = content;
            }
            return updatedContent;
          });
          scrollToBottom();
        }

        if (response.questions) {
          questions = response.questions;
          setInternalSuggestedQuestions(questions);
        }

        if (response.stream_stopped || stopStreamRef.current) {
          setIsAnswerLoading(false);
          setWaitingResponse(false);
          scrollToBottom();
          console.log("Special condition met, closing connection.");
          websocket.close(); // Close the connection gracefully
          console.log("Connection closed.");
          stopStreamRef.current = false;
        }
      };

      websocket.onerror = (event) => {
        setIsAnswerLoading(false);
        setWaitingResponse(false);
        console.error("WebSocket error observed:", event);
      };

      return () => {
        if (websocket.readyState === WebSocket.OPEN) {
          setIsAnswerLoading(false);
          setWaitingResponse(false);
          websocket.close();
        }
      };
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current)
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  };

  function extractContent(content) {
    const parts = content.split("_|_");

    const answer = parts.shift();

    const related_questions = parts;

    return { answer, related_questions };
  }

  const truncate = (str, n) => {
    if (!str) return "";
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const getRenderedText = (text) => {
    var rawMarkup = marked.parse(text);
    return { __html: rawMarkup };
  };

  return (
    <>
      {isLoading && !isMobileDashboardView() ? (
        <>
          <div className="position-absolute d-flex justify-content-center align-items-center w-100 h-100">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </>
      ) : null}

      {isLoading && isMobileDashboardView() ? (
        <>
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </>
      ) : null}

      <>
        {isMobileDashboardView() &&
        Object.keys(checkedItems).filter((id) => checkedItems[id]).length >
          0 ? (
          <button
            class="btn theme-btn  rounded-btn d-flex"
            style={{ margin: "0 auto" }}
            onClick={start_session}
          >
            {t("Start New Session")}
          </button>
        ) : null}

        <div
          class="row gx-lg-4 gx-0 w-100 m-0 notranslate"
          style={{ display: isMobileDashboardView() ? "none" : "" }}
        >
          {Object.keys(checkedItems).filter((id) => checkedItems[id]).length >
          0 ? (
            <div
              className="overlay position-absolute d-flex justify-content-center align-items-center w-100 h-100"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", height: "300px" }}
            >
              <div className=" p-4 text-center">
                <button
                  class="btn theme-btn  rounded-btn"
                  onClick={start_session}
                >
                  {t("Start New Session")}
                </button>
              </div>
            </div>
          ) : null}
          <div class={singleView ? "col-lg-8 col-12 ai-chat-div" : ""}>
            <div
              class=" ai-chat-div-height vertical-flex-box"
              style={{ height: isMobileDashboardView() ? "340px" : "630px" }}
            >
              <div
                class="d-flex justify-content-center align-items-center"
                style={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  paddingRight: "10px",
                }}
              >
                <p className="mb-0 text-center">{truncate(sessionName, 84)}</p>

                {!singleView && !isAnswerLoading && (
                  <span
                    class="pointer"
                    onClick={() => navigate(`/chat/${amigotorOid}`)}
                    style={{ marginLeft: "auto" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="icon bi bi-arrows-fullscreen"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707m0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707"
                      />
                    </svg>
                  </span>
                )}
              </div>

              {/* Chat messages display area */}
              <div
                className="ai-chat-msg-content card-padding"
                ref={scrollRef}
                style={{ overflowY: "auto" }}
              >
                {chatContent
                  ? chatContent.map((message, index) => {
                      const messageClass =
                        message.role === "user"
                          ? "user-message"
                          : "bot-response";

                      return message.role === "user" ? (
                        <p
                          key={index}
                          className={`chat-message ${messageClass}  `}
                        >
                          {" "}
                          {message.content}{" "}
                        </p>
                      ) : (
                        <div
                          key={index}
                          className={`chat-message ${messageClass} d-flex flex-column`}
                        >
                          <p
                            dangerouslySetInnerHTML={getRenderedText(
                              message.content
                            )}
                          ></p>

                          {message.references &&
                            message.references.length > 0 && (
                              <p style={{ color: "grey" }}>
                                {t("Source:")} {message.references.join(", ")}
                              </p>
                            )}
                        </div>
                      );
                    })
                  : null}
                {waitingResponse ? (
                  <div
                    class={`chat-message bot-response  text-center`}
                    style={{ marginRight: "90%" }}
                  >
                    <div class="loading-threedots">
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </div>
                  </div>
                ) : null}
                {chatContent.length === 0 ?
                 
                  internalSuggestedQuestions.map((suggestion, index) => (
                    suggestion !== "" &&
                    <button
                      key={index}
                      className="btn btn-sm green-outline-btn"
                      onClick={() => generate_answer_stream(suggestion)}
                    >
                      {suggestion}
                    </button>
                  ))
                 :
                internalSuggestedQuestions[questionIndex] && (
                  <div className="ai-chat-suggestions d-flex flex-column align-items-end mb-3">
                    <button
                      key={questionIndex}
                      className="btn btn-sm green-outline-btn"
                      onClick={() =>
                        generate_answer_stream(
                          internalSuggestedQuestions[questionIndex]
                        )
                      }
                    >
                      {internalSuggestedQuestions[questionIndex]}
                    </button>
                  </div>
                )}
              </div>

              <div class="ai-chat-msg-box ">
                {sessionName !== t("Talk With All Files") && !singleView && (
                  <div class="d-flex">
                    <div className="toggle-container d-flex align-items-center justify-content-center">
                      {internetEnabled ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          class="bi bi-wifi"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.44 12.44 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.52.52 0 0 0 .668.05A11.45 11.45 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049" />
                          <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.46 9.46 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065m-2.183 2.183c.226-.226.185-.605-.1-.75A6.5 6.5 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.5 5.5 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091zM9.06 12.44c.196-.196.198-.52-.04-.66A2 2 0 0 0 8 11.5a2 2 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          class="bi bi-wifi-off"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.706 3.294A12.6 12.6 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.52.52 0 0 0 .668.05A11.45 11.45 0 0 1 8 4q.946 0 1.852.148zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.45 8.45 0 0 1 3.51-1.27zm2.596 1.404.785-.785q.947.362 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.5 8.5 0 0 0-1.98-.932zM8 10l.933-.933a6.5 6.5 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.53.53 0 0 1-.611.09A5.5 5.5 0 0 0 8 10m4.905-4.905.747-.747q.886.451 1.685 1.03a.485.485 0 0 1 .047.737.52.52 0 0 1-.668.05 11.5 11.5 0 0 0-1.811-1.07M9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A2 2 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75z" />
                        </svg>
                      )}

                      <label className="switch mx-2">
                        <input
                          type="checkbox"
                          onChange={handleToggleChange}
                          checked={internetEnabled}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                )}
                <input
                  type="text"
                  class="form-control round-input border-input"
                  name=""
                  placeholder={t("Type here")}
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !isAnswerLoading) {
                      generate_answer_stream();
                    }
                  }}
                />
                <button
                  class="btn theme-btn d-flex rounded-btn ms-2"
                  onClick={() => generate_answer_stream()}
                  disabled={isAnswerLoading ? true : false}
                >
                  <span class="me-2">
                    <img src={ChatBotWhite} class="img-fluid " alt="img" />
                  </span>
                  {t("Ask")}
                </button>
                {chatContent.length > 0 && internalSuggestedQuestions.length > 0 && (
                <div
                  onClick={() => {
                    if (questionIndex < internalSuggestedQuestions.length - 1) {
                      setQuestionIndex(questionIndex + 1);
                    } else {
                      setQuestionIndex(0);
                    }
                    setTimeout(() => {
                      scrollToBottom();
                    }, 100);
                  }}
                  style={{
                    marginLeft: "6px",
                    marginTop: "6px",
                  }}
                  className="pointer"
                >
                  <svg
                    viewBox="0 0 24 24"
                    className="command-svg me-2 mb-2"
                    width="32px"
                    height="32px"
                  >
                    <g>
                      <rect fill="none" height="20" width="20" x="0"></rect>
                    </g>
                    <g>
                      <g>
                        <path
                          fill="#009688"
                          d="M20.45,6l0.49-1.06L22,4.45c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49L20.45,2c-0.18-0.39-0.73-0.39-0.91,0l-0.49,1.06 L18,3.55c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L19.55,6C19.72,6.39,20.28,6.39,20.45,6z"
                        ></path>
                        <path
                          fill="#009688"
                          d="M8.95,6l0.49-1.06l1.06-0.49c0.39-0.18,0.39-0.73,0-0.91L9.44,3.06L8.95,2C8.78,1.61,8.22,1.61,8.05,2L7.56,3.06L6.5,3.55 c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L8.05,6C8.22,6.39,8.78,6.39,8.95,6z"
                        ></path>
                        <path
                          fill="#009688"
                          d="M19.55,13.5l-0.49,1.06L18,15.05c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49l0.49,1.06c0.18,0.39,0.73,0.39,0.91,0l0.49-1.06 L22,15.95c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49l-0.49-1.06C20.28,13.11,19.72,13.11,19.55,13.5z"
                        ></path>
                        <path
                          fill="#009688"
                          d="M17.71,9.12l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0L2.29,17.46c-0.39,0.39-0.39,1.02,0,1.41l2.83,2.83 c0.39,0.39,1.02,0.39,1.41,0l11.17-11.17C18.1,10.15,18.1,9.51,17.71,9.12z M14.21,11.21l-1.41-1.41l1.38-1.38l1.41,1.41 L14.21,11.21z"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div> )}
              </div>
            </div>
          </div>
          {singleView && (
            <div class="col-lg-4 col-12">
              <div class="">
                <p class="ft-18-title mb-4">
                  <span class="theme-color me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.022"
                      height="23.645"
                      viewBox="0 0 23.022 23.645"
                    >
                      <g id="settings" transform="translate(-2.715 -2.295)">
                        <path
                          id="Path_171"
                          data-name="Path 171"
                          d="M23.537,14.761v-1.3l1.626-1.423a1.694,1.694,0,0,0,.321-2.16l-2-3.387a1.707,1.707,0,0,0-2.007-.762l-2.058.694a9.612,9.612,0,0,0-1.11-.635l-.432-2.134a1.694,1.694,0,0,0-1.694-1.363H12.222a1.694,1.694,0,0,0-1.694,1.363L10.1,5.792a9.722,9.722,0,0,0-1.118.635L6.963,5.7a1.694,1.694,0,0,0-.541-.05A1.694,1.694,0,0,0,4.957,6.5l-2,3.387a1.694,1.694,0,0,0,.347,2.126l1.6,1.465v1.3l-1.6,1.423a1.694,1.694,0,0,0-.347,2.16l2,3.387a1.707,1.707,0,0,0,2.007.762l2.058-.694a9.612,9.612,0,0,0,1.11.635l.432,2.134a1.694,1.694,0,0,0,1.694,1.363h4a1.694,1.694,0,0,0,1.694-1.363l.432-2.134a9.722,9.722,0,0,0,1.118-.635l2.05.694a1.707,1.707,0,0,0,2.007-.762l1.931-3.387a1.694,1.694,0,0,0-.347-2.126Zm-1.516,6.131-2.9-.982a7.5,7.5,0,0,1-2.3,1.329l-.6,3.04h-4l-.6-3.007a7.927,7.927,0,0,1-2.287-1.329l-2.912.948-2-3.387,2.3-2.032a7.538,7.538,0,0,1,0-2.651l-2.3-2.092,2-3.387,2.9.982A7.5,7.5,0,0,1,11.622,7l.6-3.04h4l.6,3.007a7.927,7.927,0,0,1,2.287,1.329l2.912-.948,2,3.387-2.3,2.032a7.538,7.538,0,0,1,0,2.651l2.3,2.092Z"
                          transform="translate(0 0)"
                          fill="currentColor"
                        />
                        <path
                          id="Path_172"
                          data-name="Path 172"
                          d="M16.331,21.412a5.031,5.031,0,1,1,3.608-1.473A5.081,5.081,0,0,1,16.331,21.412Zm0-8.469a3.311,3.311,0,1,0,2.418.969,3.311,3.311,0,0,0-2.418-.969Z"
                          transform="translate(-2.11 -2.214)"
                          fill="currentColor"
                        />
                      </g>
                    </svg>
                  </span>
                  {t("Chat Settings")}
                </p>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setIsModeDropdownOpen(false);
                  }}
                >
                  <div class="form-group selectBox" ref={modeRef}>
                    <label>{t("Mode")}</label>
                    <div
                      className="form-control round-input border-input selectBox__value"
                      onClick={() => {
                        setIsModeDropdownOpen(!isModeDropdownOpen);
                      }}
                    >
                      {modes[mode]}
                    </div>
                    {isModeDropdownOpen && (
                      <div className="dropdown-menu">
                        <a
                          href="#"
                          className={`dropdown-item ${
                            mode === "general" ? "active" : ""
                          }`}
                          onClick={() => handleSelect("general", "mode")}
                        >
                          {t("General")}
                        </a>

                        <a
                          href="#"
                          className={`dropdown-item ${
                            mode === "custom" ? "active" : ""
                          }`}
                          onClick={() => handleSelect("custom", "mode")}
                        >
                          {t("Custom")}
                        </a>
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
                {mode === "custom" && (
                  <div class="d-flex align-items-center">
                    <div class="form-group w-100 ">
                      <label>{t("Prompt")}</label>
                      <input
                        type="text"
                        class="form-control round-input border-input"
                        name=""
                        placeholder={t("Give me detailed answers")}
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                      />
                    </div>
                    {customPrompt !== "" &&
                      customPrompt !== savedCustomPrompt && (
                        <span
                          class="file-action-list "
                          style={{
                            height: "36px",
                            width: "36px",
                            padding: "8px",
                            marginLeft: "10px",
                            marginTop: "10px",
                          }}
                          onClick={saveCustomPrompt}
                        >
                          <span className="hover-span">{t("Share")}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="white"
                            class="bi bi-floppy-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z" />
                            <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z" />
                          </svg>
                        </span>
                      )}
                  </div>
                )}
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setIsSourceDropdownOpen(false);
                  }}
                >
                  <div class="form-group selectBox" ref={sourceRef}>
                    <label>{t("Source")}</label>
                    <div
                      class="form-control round-input border-input selectBox__value"
                      onClick={() => {
                        setIsSourceDropdownOpen(!isSourceDropdownOpen);
                      }}
                    >
                      {t(sources[source])}
                    </div>
                    {isSourceDropdownOpen && (
                      <div class="dropdown-menu">
                        <a
                          href="#"
                          className={`dropdown-item ${
                            source === "my-file" ? "active" : ""
                          }`}
                          onClick={() => handleSelect("my-file", "source")}
                        >
                          {t("My Files")}
                        </a>
                        <a
                          href="#"
                          className={`dropdown-item ${
                            source === "internet" ? "active" : ""
                          }`}
                          onClick={() => handleSelect("internet", "source")}
                        >
                          {t("Internet")}
                        </a>
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
                {source === "my-file" && files && files.length > 0 && (
                  <span className="d-flex align-items-center justify-content-between mb-3">
                    {t("Source Files:")}

                    <SourceFilesDropdown
                      files={files}
                      onDeleteSouceFile={onDeleteSouceFile}
                      fetchChatData={get_chat}
                      amigotorOid={amigotorOid}
                    />
                  </span>
                )}
                <div class="d-flex align-items-start">
                  <span class="info-icon theme-color">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.376"
                      height="16.62"
                      viewBox="0 0 16.376 16.62"
                    >
                      <g id="info" transform="translate(-2.239 -2.244)">
                        <path
                          id="Path_124"
                          data-name="Path 124"
                          d="M11.229,2.284a8.073,8.073,0,0,1,4.977,2.4,8.382,8.382,0,0,1,.546,11.148A8.132,8.132,0,0,1,6.566,17.894a8.31,8.31,0,0,1-3.7-4.155,8.453,8.453,0,0,1-.273-5.592,8.31,8.31,0,0,1,3.288-4.5,8.073,8.073,0,0,1,5.352-1.364Zm.56,15.254a6.992,6.992,0,0,0,4.049-2.457,7.206,7.206,0,0,0-.475-9.569,6.909,6.909,0,0,0-8.821-.879A7.194,7.194,0,0,0,7.136,16.85a6.9,6.9,0,0,0,4.654.689ZM9.813,8.183H11.3V7H9.813ZM11.3,9.37v4.749H9.813V9.37Z"
                          transform="translate(0 0)"
                          fill="currentColor"
                          fill-rule="evenodd"
                        />
                      </g>
                    </svg>
                  </span>
                  <p
                    class="mb-0"
                    style={{ display: mode === "general" ? "" : "none" }}
                  >
                    {t(
                      "In General mode, you can engage in a direct conversation with the AI"
                    )}
                  </p>
                  <p
                    class="mb-0"
                    style={{ display: mode === "custom" ? "" : "none" }}
                  >
                    {t(
                      "In Custom mode, you can personalize your interaction by specifying the prompt you wish to use in your chat with the AI"
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}{" "}
        </div>
      </>
    </>
  );
}

export default AiChat;
