// FileItem.js
import React, { useRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useModal } from '../../components/common/Modal/ModalContext'
import renameSvg from '../../assets/img/icon/rename.svg'
import moveSvg from '../../assets/img/icon/move.svg'
import sharePeopleSvg from '../../assets/img/icon/share-people.svg'
import shareSvg from '../../assets/img/icon/share.svg'
import deleteSvg from '../../assets/img/icon/delete.svg'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../components/common/LanguageProvider';

function ExplorerItem({ file, index, isChecked, onCheckboxChange, onProcessing, onProcessingFileClicked, sendOid }) {

    const { t } = useTranslation();
    const uid = file?.PK?.S.replace('User#', "");
    const tid = file?.SK?.S.replace('#AmigotorFile#', '');
    const name = file?.TempName?.S;
    const orderid = file?.OrderID?.S;
    const navigate = useNavigate();

    var date_flag = false;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    try {
        var myDate;
        if (file?.Date?.N) {
            myDate = new Date(parseInt(file.Date.N)); // assuming file.Date.N is a Unix timestamp in milliseconds
        } else {
            myDate = new Date(); // using current date
        }
        var currentDate = Date.now();
        var diffInMs = currentDate - myDate.getTime();
        if (diffInMs < (1000 * 60 * 60 * 24)) {
            date_flag = true;
        }
    } catch (error) {
        console.error("Error while parsing and comparing dates:", error);
    }
    var status = file?.TStatus?.S;
    if (status == "Processing" && !date_flag) {
        status = "";
    }

    const handleNavigate = () => {
        // Condition to decide whether to navigate or show popup
        if (status == "Processing") {
            onProcessingFileClicked(tid);
        } else {
            navigate(`/file/${orderid}`);
        }
    };

    const truncate = (str, maxLength) => {
        return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
    };


    var myDate = new Date(parseInt(file?.Date?.N)); // assuming r.Date.N is a Unix timestamp in milliseconds

    let timer = myDate.toLocaleString();
    let timer2 = myDate.toLocaleDateString();
    const { showModal } = useModal();

    return (
        <tr>
            <th className="web-view">
                <div className='checkbox custom d-inline-block' >
                    <label>
                        <input type="checkbox" checked={isChecked} onChange={status !== "Processing" ? (e) => onCheckboxChange(sendOid ? file?.OrderID?.S : file?.SK?.S, e.target.checked) : null} />
                    </label>
                </div>
            </th>

            <td class="pointer" onClick={handleNavigate}>
                <a
                    href={status === "Processing" ? "#" : `/file/${orderid}`}
                >
                    <span class="dark-color regular " >{truncate(name, 30)}</span>
                </a>
            </td>

            <td class="text-end" onClick={handleNavigate} style={{ padding: '12px' }}>
                <a
                    href={status === "Processing" ? "#" : `/file/${orderid}`}
                    style={{ cursor: 'pointer' }}> {timer2}
                </a>
            </td>
        </tr>
    );
}

export default ExplorerItem;
