import React, { useEffect, useState } from 'react';
import Files from "./Files/Files";
import Sessions from "./Sessions/Sessions";
import { useTranslation } from '../components/common/LanguageProvider';

function FilesContainer(props) {
    const [activeTab, setActiveTab] = useState('files');

    const { t } = useTranslation();
    const isFile = props.isFile || false;
    const isFolder = props.isFolder || false;
    const isWorkspace = props.isWorkspace || false;

    useEffect(() => {
        const lastTab = localStorage.getItem('fileSessionTab');
        if (lastTab) {
            setActiveTab(lastTab);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('fileSessionTab', activeTab);
    } , [activeTab]);
    
   

    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };
    return (
        <div className="file-session-container">
            <div className= {isWorkspace  ? "file-session-tab-bar-workspace" : "file-session-tab-bar"}>
                <button className={activeTab === 'files' ? 'file-session-tab active' : 'file-session-tab'} onClick={() => setActiveTab('files')}>
                   {t('Files')}
                </button>
                <button className={activeTab === 'sessions' ? 'file-session-tab active' : 'file-session-tab'} onClick={() => setActiveTab('sessions')}>
                {t('Sessions')}
                </button>
            </div>
            
            {activeTab === 'files' ? <Files isFile={isFile} isFolder={isFolder} /> : <Sessions isFile={isFile} isFolder={isFolder} />}
        </div>
    );
}

export default FilesContainer;
