import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../Auth/AuthContext';

import { useNavigate, useLocation } from "react-router-dom";
import Files from './Files/Files';
import { useModal } from '../components/common/Modal/ModalContext';
import AiChat from './Editor/AiChat';
import Explorer from './Explorer/Explorer';
import { fetchData } from '../hooks/apiUtils';

import ImportFile from './ImportFile';
import Sessions from './Sessions/Sessions';
import { useTranslation } from '../components/common/LanguageProvider';
function Dashboard({ isBlocked, setIsBlocked }) {
  const { t } = useTranslation();
  const { hashedId, planInfo, userData } = useAuth();
  const [credits, setCredits] = useState(30);
  const [tid, setTid] = useState(null);
  const [amigotorOid, setAmigotorOid] = useState(null);

  const [checkedItems, setCheckedItems] = useState({});

  const [allFiles, setAllFiles] = useState([]);
  const [filesLength, setFilesLength] = useState(0);
  const [isExplorerLoading, setIsExplorerLoading] = useState(true);
  const [sessionName, setSessionName] = useState("");
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);

  const navigate = useNavigate();
  const { showModal } = useModal();

  useEffect(() => {
    if (planInfo) {
      setCredits(planInfo.allowed - planInfo.total_orders);
    }
  }, [planInfo]);
  useEffect(() => {
    if (!localStorage.getItem("transcript-language"))
      findLanguage();
    if (!localStorage.getItem("isLanguageSet")) {
      localStorage.setItem("isLanguageSet", true);
      showModal("LANGUAGE-MODAL");

    }
  }, []);

  const fetchFiles = async () => {
    setIsExplorerLoading(true); // Set loading to true at the beginning of data fetching

    var url = "https://4g55e4e6dd.execute-api.eu-central-1.amazonaws.com/default/Amigotor-ListFiles?uid=" + hashedId + "&app=Amigotor";

    const data = await fetchData(url);
    const files = data.files ?? data;
    files.sort((a, b) => {
      return parseInt(b.Date.N) - parseInt(a.Date.N);
    });
    setAllFiles(files);
    setFilesLength(files.length);
    setIsExplorerLoading(false); // Set loading to false once data fetching is complete
  }


  const findLanguage = async () => {
    try {
      const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
      const [iso639_1, countryCode] = browserLanguage.split("-");

      localStorage.setItem("dashboard-language", iso639_1);
      localStorage.setItem("dashboard-country", countryCode || 'US');
      localStorage.setItem("transcript-language", browserLanguage);
      window.location.reload();

    } catch (error) {
      console.error('Error detecting browser language:', error);
    }
  };

  const whenNewFileUploaded = (sid, sname) => {
    setAmigotorOid(sid);
    setSessionName(sname);
    fetchFiles();
    if (isMobileDevice()) {
      navigate(`/chat/${sid}`)
    }
  };

  const onSessionClicked = (fid, name) => {
    if (isAnswerLoading) return;

    if (fid !== amigotorOid) {
      setAmigotorOid(fid);
      setSessionName(name);
    }
    if (isMobileDevice()) {
      navigate(`/chat/${fid}`)
    }
  };

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };


  const handleCheckboxChange = (fileId, isChecked) => {
    if (isAnswerLoading) return;
    setCheckedItems(prevState => {
      const tempArray = { ...prevState };

      tempArray[fileId] = isChecked;

      const checkedIds = Object.keys(tempArray).filter(id => tempArray[id]);

      
      return tempArray;
    });
  };

  useEffect(() => {
    console.log(checkedItems, "checkedItems")
  }, [checkedItems]);



  return (
    <>

      <div className='row justify-content-center'>

        <div className='col-lg-4 '>

          <div className='card card-padding  card-margin'>
            <Explorer checkedItems={checkedItems} handleCheckboxChange={handleCheckboxChange} isExplorerLoading={isExplorerLoading} allFiles={allFiles} filesLength={filesLength} fetchFiles={fetchFiles} />
          </div>
          <ImportFile setIsBlocked={setIsBlocked} whenNewFileUploaded={whenNewFileUploaded} />

        </div>
        <div className='col-lg-4 '>
          <div className={isMobileDevice() ? '' : 'card card-100 '}>
            <AiChat
              isAnswerLoading={isAnswerLoading}
              setIsAnswerLoading={setIsAnswerLoading}
              tid={tid} checkedItems={checkedItems} amigotorOid={amigotorOid} setAmigotorOid={setAmigotorOid} sessionName={sessionName} setSessionName={setSessionName} setCheckedItems={setCheckedItems} />
          </div>
        </div>

        <div className='col-lg-4 '>
          <div className='card card-padding card-margin'>
            <Sessions onSessionClicked={onSessionClicked} amigotorOid={amigotorOid} hideOptions={true} isDashboard />
          </div>
          <ImportFile setIsBlocked={setIsBlocked} whenNewFileUploaded={whenNewFileUploaded} isTypePaste />

        </div>


        <div style={{ height: '100px', display: isMobileDevice() ? '' : 'none' }}>

        </div>


      </div>
    </>
  );
}

export default Dashboard;



