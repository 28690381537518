import React, { useRef, useEffect, useState } from 'react';
import { useParams, Link, useLocation } from "react-router-dom";

import '../assets/css/bootstrap.min.css';
import '../assets/css/font-awesome.css';
import '../assets/css/custome.css';
import '../assets/css/responsive.css';

import { useAuth } from '../Auth/AuthContext';
import { useNavigate } from 'react-router-dom';

import rename from "../assets/img/icon/rename.svg";
import fileMark from '../assets/img/files/file-mark.svg';
import undo from '../assets/img/files/undo.svg';
import redo from '../assets/img/files/redo.svg';
import share from '../assets/img/files/share.svg';
import { fetchData, fetchDataPost } from "../hooks/apiUtils";
import { showToast } from "../components/common/toast";
import { useModal } from "../components/common/Modal/ModalContext";
import logoTranskriptor from '../assets/img/logo-transkriptor-nobuffer.png';

import logoAmigotor from '../assets/img/logo_white_nobuffer.png';
import { useTranslation } from '../components/common/LanguageProvider';
import LoadingModal from '../components/common/Modal/LoadingModal';
import logoRecorditor from '../assets/img/logo_recorditor.png';
import logoMeetingtor from '../assets/img/logo_meetingtor.png';


function FileSingleView({ handleOpenSidebar }) {
	const { t } = useTranslation();
	const { oid } = useParams();
	const { userData, hashedId } = useAuth();
	const [userStatus, setUserStatus] = useState(null);
	const [fileContent, setFileContent] = useState(null);
	const [transcription, setTranscription] = useState(null);
	const [speakerList, setSpeakerList] = useState([]);
	const [duration, setDuration] = useState(null);
	const [date, setDate] = useState(null);
	const [sk, setSk] = useState(null);
	const [recordingName, setRecordingName] = useState(null);
	const saveRef = useRef(null);
	const videoRef = useRef(null);
	const [index, setIndex] = useState(0);
	const navigate = useNavigate();

	const [contentLoading, setContentLoading] = useState(true);

	const [loading, setLoading] = useState(false);
	const [transkriptorSK, setTranskriptorSK] = useState(null)
	const [recorditorSK, setRecorditorSK] = useState(null)
	const [meetingtorSk, setMeetingtorSk] = useState(null)
	const [orderPk, setOrderPk] = useState(null)

	const { showModal } = useModal();

	const undoRef = useRef(null);
	const redoRef = useRef(null);

	useEffect(() => {
		shareLink();
	}, []);


	useEffect(() => { getFileInfo(); }, [
		oid
	]);
	useEffect(() => {
		if (userData)
			setUserStatus(userData.SStatus);
	}, [userData]);

	const handleUndo = (e) => {
		console.log("undo");
		e.preventDefault();
		document.execCommand('undo', false, null);
	}

	const start_session = async () => {

		setLoading(true);
		var url = process.env.REACT_APP_MODE === 'development' ? "https://kaj6gbm9fd.execute-api.eu-central-1.amazonaws.com/default/Amigotor-StartSession" : "https://ne6bu8gjzj.execute-api.eu-central-1.amazonaws.com/default/Amigotor-StartSession"
		try {
			const response = await fetch(url,
				{
					method: 'POST',
					body: JSON.stringify(
						{
							"uid": hashedId,
							"files": ["#AmigotorFile#" + sk]
						})
				}
			);

			if (!response.ok) {
				throw new Error('HTTP status ' + response.status);
			} else {
				const data = await response.json();
				setLoading(false);
				navigate(`/chat/${data.orderid}`);
				return;
			}

		} catch (error) {
			console.error('Error:', error);
		}

	};


	// Render the component
	useEffect(() => {
		if (transcription) {
			const nonEmptyItem = transcription.find(item => item.text.trim());

			if (nonEmptyItem) {
				const speakers = [...new Set(transcription.map(item => item.Speaker || "Speaker 1"))];
				setSpeakerList(speakers);
			}
		}

	}, [transcription]);



	function convertMsToFormattedDate(ms) {
		ms = parseInt(ms);
		const date = new Date(ms);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns a 0-based value
		const year = date.getFullYear();
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
	}

	const isMobileDevice = () => {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	};



	const onRename = async (tid, uid, name) => {
		setRecordingName(name);
		const response = await fetchDataPost(
			"https://v91m7qwz82.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFile?",
			{ tid: "#AmigotorFile#" + tid, Tname: name, cid: "User#" + uid }
		);
		if (response)
			showToast(t("File renamed"), { type: "success" });
	};

	const shareLink = async () => {
		try {
			// Get the current origin (e.g., "http://localhost:3000" or "https://app.transkriptor.com")
			let baseUrl = window.location.origin;

			let link = baseUrl + "/file/" + oid;

			await navigator.clipboard.writeText(link);

			showToast(t("File link copied to clipboard"));

			return true;

		} catch (err) {
			console.error('Failed to copy text: ', err);
			return false;
		}
	};


	const getFileInfo = async () => {
		fetch(`https://6ee7wjwnm0.execute-api.eu-central-1.amazonaws.com/default/Amigotor-GetFileContent?oid=${oid}`).then(res => res.json()).then((result) => {
			setFileContent(result?.content);
			setSk(result?.sk.replace("#AmigotorFile#", ""));
			setOrderPk(result?.file?.PK?.replace("User#", ""))
			setRecorditorSK(result?.file?.RecorditorSK?.replace("#Recorditor#", ""));
			setTranskriptorSK(result?.file?.TranskriptorSK?.replace("#Transkription#", ""));
			setMeetingtorSk(result?.file?.MeetingtorSK?.replace("#Meetingtor#", ""));
			setRecordingName(result?.filename);
			setDate(convertMsToFormattedDate(result?.date));
			setContentLoading(false);
		}).catch((error) => {
			console.log(error);
		});
	};








	useEffect(() => {
		const handleCopy = (e) => {
			if (userStatus && userStatus === "Trial") {
				e.preventDefault();
				showModal("UPGRADE-DOWNLOAD");
			}
		};

		document.addEventListener('copy', handleCopy);

		return () => {
			document.removeEventListener('copy', handleCopy); // Cleanup the event listener on component unmount
		};
	}, [userStatus]);


	const formatTime = (seconds) => {
		const hours = Math.floor(seconds / 3600);
		seconds %= 3600;
		const min = Math.floor(seconds / 60);
		const sec = Math.floor(seconds % 60);

		if (hours > 0) {
			return `${hours}:${min.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
		} else {
			return `${min}:${sec.toString().padStart(2, "0")}`;
		}
	};


	const checkUser = async () => {
		const response = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
		if (response.SStatus === "Trial") {
			showModal("UPGRADE-DOWNLOAD");
		} else {
			// Create a new anchor element
			const link = document.createElement('a');

		}
	}

	return (
		<div class="container-fluid p-0">
			<LoadingModal show={loading} message={`${t("Starting new session...")}`} />
			<div class="row justify-content-center">
				<>
					<div className="container-fluid p-0">
						<div className="row justify-content-center">
							<div className="col-12">
								<div className="card card-padding overflow-visible">
									<div className="row align-items-center">
										<div className="col-xxl-4 col-lg-4 col-md-4 col-12 mb-2 order-xxl-1 order-lg-1 order-md-1 order-1 text-md-start text-center notranslate">
											<h2 class="small-title regular mb-2">
												<span class="me-2 burger-web-view">
													<img
														src={fileMark}
														class="img-fluid"
														height="auto"
														width="28px"
														alt="img"
													/>
												</span>
												{!isMobileDevice() &&
													<span class="me-2 burger-mobile-view" onClick={() => handleOpenSidebar()}>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-black" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 12H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 6H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 18H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>

													</span>}
												<div className="filename-container">
													<span className="filename">{recordingName}</span>
													<Link
														onClick={() =>
															showModal(
																"RENAME",
																{ name: recordingName, tid: oid, uid: hashedId },
																{ onRename }
															)
														}
														className="web-view"
													>
														<span
															class="icons-hover"
															data-bs-toggle="tooltip"
															data-bs-placement="bottom"
															title={t("Rename")}
															data-bs-original-title="Rename"
														>
															<img src={rename} class="img-fluid" alt="img" />
														</span>
													</Link>
												</div>
											</h2>
											<p class="d-sm-inline-block d-block ft-14 mb-1 me-sm-4 me-0 web-view">
												<span class="me-1">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="14"
														height="16"
														viewBox="0 0 19.2 18"
													>
														<g id="date-line" transform="translate(-2 -2)">
															<path
																id="Path_135"
																data-name="Path 135"
																d="M20.15,6H18.2V7.2H20V20.4H3.2V7.2H5V6H3.05A1.068,1.068,0,0,0,2,7.086V20.514A1.068,1.068,0,0,0,3.05,21.6h17.1a1.068,1.068,0,0,0,1.05-1.086V7.086A1.068,1.068,0,0,0,20.15,6Z"
																transform="translate(0 -1.6)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_136"
																data-name="Path 136"
																d="M8,14H9.2v1.2H8Z"
																transform="translate(-2.4 -4.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_137"
																data-name="Path 137"
																d="M14,14h1.2v1.2H14Z"
																transform="translate(-4.8 -4.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_138"
																data-name="Path 138"
																d="M20,14h1.2v1.2H20Z"
																transform="translate(-7.2 -4.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_139"
																data-name="Path 139"
																d="M26,14h1.2v1.2H26Z"
																transform="translate(-9.6 -4.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_140"
																data-name="Path 140"
																d="M8,19H9.2v1.2H8Z"
																transform="translate(-2.4 -6.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_141"
																data-name="Path 141"
																d="M14,19h1.2v1.2H14Z"
																transform="translate(-4.8 -6.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_142"
																data-name="Path 142"
																d="M20,19h1.2v1.2H20Z"
																transform="translate(-7.2 -6.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_143"
																data-name="Path 143"
																d="M26,19h1.2v1.2H26Z"
																transform="translate(-9.6 -6.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_144"
																data-name="Path 144"
																d="M8,24H9.2v1.2H8Z"
																transform="translate(-2.4 -8.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_145"
																data-name="Path 145"
																d="M14,24h1.2v1.2H14Z"
																transform="translate(-4.8 -8.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_146"
																data-name="Path 146"
																d="M20,24h1.2v1.2H20Z"
																transform="translate(-7.2 -8.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_147"
																data-name="Path 147"
																d="M26,24h1.2v1.2H26Z"
																transform="translate(-9.6 -8.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_148"
																data-name="Path 148"
																d="M9.6,6.8a.6.6,0,0,0,.6-.6V2.6A.6.6,0,0,0,9,2.6V6.2A.6.6,0,0,0,9.6,6.8Z"
																transform="translate(-2.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_149"
																data-name="Path 149"
																d="M25.6,6.8a.6.6,0,0,0,.6-.6V2.6a.6.6,0,0,0-1.2,0V6.2A.6.6,0,0,0,25.6,6.8Z"
																transform="translate(-9.2)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_150"
																data-name="Path 150"
																d="M13,6h6V7.2H13Z"
																transform="translate(-4.4 -1.6)"
																fill="#6b6c6d"
															/>
														</g>
													</svg>
												</span>
												{date}
											</p>

										</div>
										<div class="col-xxl-5 col-lg-4 col-md-4 col-12 order-xxl-2 order-lg-2 order-md-3 order-3">
										</div>
										<div class="col-xxl-3 col-lg-4 col-md-4 col-12 order-xxl-3 order-lg-3 order-md-2 order-2 text-md-end text-center notranslate">
											<div class="file-action-icon-flex mb-3">
												<Link ref={undoRef} onClick={handleUndo} style={{ display: "none" }} className="hover-effect">
													<span
														class="file-action-list"
														data-bs-toggle="tooltip"
														data-bs-placement="bottom"
														title={t("Undo")}
													>
														<img src={undo} class="img-fluid" alt="img" />
													</span>
												</Link>
												<Link ref={redoRef} style={{ display: "none" }} className="hover-effect">
													<span
														class="file-action-list"
														data-bs-toggle="tooltip"
														data-bs-placement="bottom"
														title={t("Redo")}
														ref={redoRef}
													>
														<img src={redo} class="img-fluid" alt="img" />
													</span>
												</Link>
												<Link
													onClick={(e) => {
														e.preventDefault();
														checkUser()
													}}
													className="hover-effect"
												>
													<span
														class="file-action-list theme-color"

													>
														<span className="hover-span">
															{t("Download")}
														</span>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="21"
															height="19.34"
															viewBox="0 0 21 19.34"
														>
															<g
																id="Group_46"
																data-name="Group 46"
																transform="translate(-0.16)"
															>
																<rect
																	id="Rectangle_36"
																	data-name="Rectangle 36"
																	width="21"
																	height="15"
																	rx="3"
																	transform="translate(21.16 15.34) rotate(180)"
																	fill="#fff"
																/>
																<rect
																	id="Rectangle_37"
																	data-name="Rectangle 37"
																	width="21"
																	height="5"
																	rx="2.5"
																	transform="translate(21.16 17.34) rotate(180)"
																	fill="currentColor"
																/>
																<rect
																	id="Rectangle_38"
																	data-name="Rectangle 38"
																	width="21"
																	height="5"
																	rx="2.5"
																	transform="translate(21.16 19.34) rotate(180)"
																	fill="#fff"
																/>
																<g
																	id="arrow-small-down"
																	transform="translate(5.402)"
																>
																	<path
																		id="Path_163"
																		data-name="Path 163"
																		d="M21.767,18.641l-4.612,4.613H15.862L11.25,18.641l1.292-1.31,3.044,3.026V15H17.43v5.357l3.045-3.045Z"
																		transform="translate(-11.25 -15)"
																		fill="currentColor"
																	/>
																</g>
															</g>
														</svg>

													</span>
												</Link>
												<Link class="share_btn hover-effect" onClick={(e) => {
													e.preventDefault();
													shareLink()
												}}>
													<span
														class="file-action-list"
													>
														<span className="hover-span">
															{t("Share")}
														</span>
														<img src={share} class="img-fluid" alt="img" />
													</span>
												</Link>
											</div>
										</div>
									</div>
									<hr class="mt-0" />
									<div >
										<ul
											class="nav nav-pills btn-pills mb-3 notranslate"
											id="pills-tab"
											role="tablist"
										>
											<li class="nav-item" role="presentation">
												<button
													class="nav-link active"
													id="pills-traskript-tab"
													data-bs-toggle="pill"
													data-bs-target="#pills-traskript"
													type="button"
													role="tab"
													aria-controls="pills-traskript"
													aria-selected="true"
												>
													<span class="btn-tab-icon" >
														<img
															src=''
															class="img-fluid btn-reg-icon"
															alt="img"
														/>
														<img
															src={logoAmigotor}
															class="img-fluid btn-active-icon"
															alt="img"
														/>
													</span>
												</button>
											</li>
											{transkriptorSK &&
												<li class="nav-item" role="presentation">
													<button
														class="nav-link meetingtor-tab"
														type="button"
														role="tab"
														style={{ border: '1px solid #0174f3' }}
														onClick={() => {
															const url = `https://app.transkriptor.com/editor/${transkriptorSK}/${orderPk}?uid=${hashedId}`
															window.open(url, '_blank');
														}}
													>
														<span class="btn-tab-icon me-1">
															<img
																src={logoTranskriptor}
																class="img-fluid btn-reg-icon"
																alt="img"
															/>

														</span>
													</button>
												</li>}
											{recorditorSK &&
												<li class="nav-item" role="presentation">
													<button
														class="nav-link meetingtor-tab"
														type="button"
														role="tab"
														style={{ border: '1px solid #FF3938' }}
														onClick={() => {
															const url = `https://app.recorditor.com/recording/${recorditorSK}/${orderPk}?uid=${hashedId}`
															window.open(url, '_blank');
														}}
													>
														<span class="btn-tab-icon me-1">
															<img
																src={logoRecorditor}
																class="img-fluid btn-reg-icon"
																alt="img"
															/>

														</span>
													</button>
												</li>}
												{meetingtorSk &&
												<li class="nav-item" role="presentation">
													<button
														class="nav-link meetingtor-tab"
														type="button"
														role="tab"
														style={{ border: '1px solid #00D13F' }}
														onClick={() => {
															const url = `https://app.meetingtor.com/meeting/${meetingtorSk}/${orderPk}?uid=${hashedId}`
															window.open(url, '_blank');
														}}
													>
														<span class="btn-tab-icon me-1">
															<img
																src={logoMeetingtor}
																class="img-fluid btn-reg-icon"
																alt="img"
															/>

														</span>
													</button>
												</li>}
										</ul>
										<div class="tab-content" id="pills-tabContent" >
											<div
												class="tab-pane fade show active"
												id="pills-traskript"
												role="tabpanel"
												aria-labelledby="pills-traskript-tab"
											>
												{(contentLoading) ?
													<div className=' card-padding'>
														<br />
														<div className='d-flex justify-content-center text-center'>
															<span class="spinner-border text-primary"></span>
														</div>
													</div>
													:
													<div className='file-view dark-color'
														style={
															{
																position: 'relative',
															}
														}>
														<p dangerouslySetInnerHTML={{ __html: fileContent }}></p>

													</div>
												}
											</div>
											{!contentLoading && sk &&
												<button class="btn theme-btn" style={{
													position: 'absolute',
													bottom: '50px',
													right: '50%',
												}}
													onClick={start_session}
												>
													{t("Start Talking")}
												</button>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			</div>
		</div>
	);
}

export default FileSingleView;