import React, { useEffect } from 'react';
import { useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../Auth/AuthContext';
import { useState } from 'react';
import { useNavigate, Link, } from 'react-router-dom';
import { useModal } from '../components/common/Modal/ModalContext';
import dropbox from '../assets/img/icon/dropbox.svg';
import googleDrive from '../assets/img/icon/google-drive.svg';
import oneDrive from '../assets/img/icon/onedrive.svg';
import youtube from '../assets/img/icon/youtube.svg';
import uploadBox from '../assets/img/upload/upload-box.png';
import { fetchData, fetchDataPost } from '../hooks/apiUtils';
import { showToast } from '../components/common/toast';
import uploadPng from "../assets/img/icon/upload-btn-icon.svg"
import JSZip from 'jszip';
import LoadingModal from '../components/common/Modal/LoadingModal';
import textFileIcon from '../assets/img/icon/text-file.svg';
import { when } from 'jquery';
import { useTranslation } from '../components/common/LanguageProvider';

var upload_arr = [];
var upload_status = [];
function ImportFile({ setIsBlocked, whenNewFileUploaded, isTypePaste }) {
	const { t } = useTranslation();
	const { hashedId, userData, planInfo, getStorageData } = useAuth();
	const fileInput = useRef();
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [language, setLanguage] = useState(null);
	const [service, setService] = useState(null);
	const [urlUpload, setUrlUpload] = useState(false);
	const [stillProcessing, setStillProcessing] = useState(false);
	const [urlStatus, setUrlStatus] = useState(null);
	const navigate = useNavigate();
	const { showModal } = useModal();
	const [progressFileName, setProgressFileName] = useState("");
	const [typedText2, setTypedText2] = useState("")
	const [webURL, setWebURL] = useState("")
	const fileInputRef = useRef(null);
	const [files, setFiles] = useState([]);
	const uploadedOrderId = useRef(null);
	const [urlWarning, setUrlWarning] = useState(null);
	const [credits, setCredits] = useState(null);

	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (urlUpload || isLoading) {
			setIsBlocked(true);
		} else if (!urlUpload && !isLoading) {
			setIsBlocked(false);
		}
	}, [isLoading, urlUpload]);
	const [selectedFiles, setSelectedFiles] = useState(null);
	var tryCounter = 0;
	const [attachListener, setAttachListener] = useState(false);

	useEffect(() => {
		if (planInfo) {
			setCredits(planInfo.allowed - planInfo.total_orders);
		}
	}, [planInfo]);

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			event.preventDefault();
			event.returnValue = '';
		};

		if (attachListener) {
			window.addEventListener('beforeunload', handleBeforeUnload);
			return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			};
		}
	}, [attachListener]);
	const handleFileDrop = (file) => {
		const event = {
			target: {
				files: [file]
			}
		};
		handleFileChange(event);
	};

	function isValidHttpUrl(string) {
		let url;

		try {
			url = new URL(string);
		} catch (_) {
			return false;
		}

		return url.protocol === "http:" || url.protocol === "https:";
	}


	const onChangeUrl = (e) => {
		const url = e.target.value;
		setWebURL(url);
		if (url.length > 0) {
			if (isValidHttpUrl(url)) {
				setUrlWarning('');
			} else {
				setUrlWarning("Url is not valid please use 'https://website.com' format");
			}
		} else {
			setUrlWarning(null);
		}
	}

	const handleFileChange = (e) => {
		// const newFiles = [...files, ...e.target.files];
		const newFiles = [...e.target.files];
		// Filtering for valid and invalid files
		console.log(e.target.files);
		const newValidFiles = newFiles.filter(file =>
			file.type === 'text/plain' ||
			file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
			file.type === 'application/msword' ||
			// file.type === 'application/msword' ||
			file.type === 'application/pdf' ||
			file.type === 'image/jpeg' ||
			file.type === 'image/png'
		);

		const invalidFiles = newFiles.filter(file =>
			file.type !== 'text/plain' &&
			file.type !== 'application/msword' &&
			file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
			// file.type !== 'application/msword' &&
			file.type !== 'application/pdf' &&
			file.type !== 'image/jpeg' &&
			file.type !== 'image/png'
		);


		if (invalidFiles.length > 0) {
			showToast(t("File is not a valid audio or video file."), { type: "error", position: "top-center" });
		}
		// Handle the upload logic
		if (newValidFiles.length > 0) {
			newValidFiles.forEach((file) => {
				onUploadFile(file);
			});
		}
		setFiles(newFiles);  // Assuming you have a state called 'files' to store the list of files
	}


	const checkProcessing = async (oid) => {
		setStillProcessing(true);
		fetch("https://ldzk8wq6bd.execute-api.eu-central-1.amazonaws.com/default/Amigotor-CheckFileStatus?oid=" + oid).then((response) => response.json()).then(function (data) {

			if (data.error && tryCounter < 90) {
				tryCounter++
				setTimeout(() => checkProcessing(oid), 3000);

			} else if (data.error && tryCounter >= 90) {
				setLoading(false);
				showToast(t("Upload failed, please try again..."), { type: "error" });
			} else if (!data.error) {


				onUploadFinish(data.session.OrderID, data.session.TempName);
			}
		})
	};

	function outOfCredit() {
		return (credits <= 0 && userData?.SStatus === "Trial")
	}





	const removeSpecialCharsAndTrim = (filename) => {
		// Remove special characters (#, &, *) and trim whitespace
		let temp = filename.replace(/[&#*]/g, '').trim();
		return temp;
	};


	async function postData(url = "", data = {}) {
		// Default options are marked with *
		const response = await fetch(url, {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			body: JSON.stringify(data), // body data type must match "Content-Type" header
		});
		return response; // parses JSON response into native JavaScript objects
	}


	function uploadByWebLink(url) {
		setLoading(true)
		if (url.length > 0) {
			setWebURL('');
			postData("https://cj4yyyjolc.execute-api.eu-central-1.amazonaws.com/default/Amigotor-UploadFileByWeblink",
				{
					'GivenWebsite': url,
					"uid": hashedId
				}).then(
					(response) => {
						if (response.ok) {
							return response.json().then((data) => {
								uploadedOrderId.current = data["orderid"];
								onUploadFinish(data.session.orderid, data.session.title);
							})
						} else {

							showToast(t("Unable to reach the site. Please check the address and try again."), { type: "error" });
							setLoading(false)
							uploadedOrderId.current = null;
						}


					})
		} else {
			alert("Please don't leave it blank");
		}


	}

	async function convertDocxToText(file) {


		const arrayBuffer = await file.arrayBuffer();
		const zip = new JSZip();
		const contents = await zip.loadAsync(arrayBuffer);
		const documentXmlFile = contents.files['word/document.xml'];

		if (!documentXmlFile) {
			throw new Error('The specified .docx file does not contain word/document.xml.');
		}

		const documentXmlContent = await documentXmlFile.async('text');
		const parser = new DOMParser();
		const documentXml = parser.parseFromString(documentXmlContent, 'text/xml');
		const paragraphs = Array.from(documentXml.getElementsByTagName('w:p'));

		let textArray = paragraphs.map(p => {
			const textElements = Array.from(p.getElementsByTagName('w:t'));
			const texts = textElements.map(t => t.textContent);
			return texts.join("");
		});

		return textArray.join('\n');
	}


	const onUploadFinish = (sid, sname) => {
		setTimeout(function () {
			showToast(t("File uploaded successfully"), { type: "success" });
			setLoading(false)
			whenNewFileUploaded(sid, sname);
			uploadedOrderId.current = null;
			getStorageData();
		}, 3000);
	}

	const onUploadFile = async (file) => {
		setLoading(true)
		if (file.name.endsWith(".txt")) {
			const reader = new FileReader();
			reader.onload = (e) => {
				console.log(2, e.target.result);
				showToast(t("Uploaded File") + ": " + file.name, { type: "success" });
				handleUploadAmigotor(file.name, e.target.result)
				// setFileContent(e.target.result);
			};
			reader.readAsText(file, "UTF-8");
			return;
		}
		else if (file.name.endsWith('.docx') || file.name.endsWith('.doc') || file.name.endsWith('.DOCX') || file.name.endsWith('.DOC')) {
			const extractedText = await convertDocxToText(file)
			const fileName = file.name.replace(/\.[^/.]+$/, ".txt")
			lang_picker(extractedText, fileName)
		}

		else {
			showToast(t("It might take a few minutes to extract content") + ": " + file.name, { type: "success" });
			const headers = {
				'Content-Type': 'application/json',
			};
			console.log(userData, "userdata")

			handleUploadAmigotor(file.name, file)
		}
	};
	function lang_picker(raw_text, fileName) {
		setLoading(true)
		if (raw_text.length > 0) {
			setTypedText2('');
			// document.getElementById('loading-type').style.display = "block";
			var currentDateTime = new Date();
			var resultInSeconds = currentDateTime.getTime() / 1000;
			var link = document.createElement('a');
			link.download = fileName ? fileName : 'Typed text ' + Math.trunc(resultInSeconds) + '.txt';
			var blob = new Blob([raw_text], { type: 'text/plain' });
			link.href = window.URL.createObjectURL(blob);
			handleUploadAmigotor(link.download, blob);
		} else {
			alert("Please don't leave it blank");
		}
	}
	async function handleUploadAmigotor(filename, file) {
		setLoading(true)
		console.log(filename, file, hashedId)
		console.log("uploader_url")
		//document.getElementById("input-form").style.display = "none";
		var API_ENDPOINT =
			"https://znmdabf9tf.execute-api.eu-central-1.amazonaws.com/default/Amigotor-UploadFile?uid=" + hashedId + "&filename=" + encodeURIComponent(removeSpecialCharsAndTrim(filename));

		const url = API_ENDPOINT;

		try {

			const response = await fetch(url);
			const data = await response.json();

			const presignedUrl = data['url'];
			uploadedOrderId.current = data["orderid"];
			console.log('Received a presigned URL:', presignedUrl);
			if (!response.ok) {
				if (response.status === 406) {

					if (data.limitSurpassed) {
						showModal("UPGRADE-RECORD");
					} else {
						showToast(data.message, { type: "error" });
					}
				}
				else {
					showToast(data.message, { type: "error" });
				}
				return;
			}

			uploadedOrderId.current = data["orderid"];


			await uploadS3(
				data["url"],
				file,
				function (percent) {
					console.log(percent);
				}
			);
			const c_url = process.env.REACT_APP_MODE === 'development' ? `https://t5czbo8xod.execute-api.eu-central-1.amazonaws.com/default/Amigotor-CompleteUpload?order_id=${uploadedOrderId.current}`  : `https://de2eb41hf7.execute-api.eu-central-1.amazonaws.com/default/Amigotor-CompleteUpload?order_id=${uploadedOrderId.current}`
			const res = await fetch(c_url);

			if (!res.ok) {
				if (res.status === 503) {
					tryCounter = 0;
					await checkProcessing(data["orderid"]);
				}
			} else {
				const responseData = await res.json();

				onUploadFinish(responseData.session.orderid, responseData.session.title);
			}

		}
		catch (err) {
			console.error('Error uploading file:', err);
			showToast(t("Error uploading file"), { type: "error" });
			setLoading(false)
		}
	}
	const uploadS3 = (url, file) => {
		return new Promise(function (resolve, reject) {
			const xhr = new XMLHttpRequest();

			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						resolve(xhr);
					} else {
						reject(xhr);
					}
				}
			};
			xhr.open('PUT', url);
			xhr.send(file);
		});
	};


	return (
		<>
			<LoadingModal show={loading} message={`${t("Uploading")}`} />


			<div class="" style={{ display: isLoading || urlUpload ? "none" : "" }} onClick={() => {
				if (outOfCredit()) {
					showModal("UPGRADE-UPLOAD");
					return;
				}
			}}>
				{!isTypePaste ?
					<div class="card card-padding   flex-center  justify-content-between" >

						<div class="text-center">
							<p class="ft-18-title mb-2">{t("Upload File")}</p>
							<p>{t("Upload a file and talk with it")}</p>
						</div>
						<div
							className="drag-upload-div"
							onClick={() => fileInputRef.current.click()}
							onDragOver={(e) => {
								if (outOfCredit()) {
									showModal("UPGRADE-UPLOAD");
									return;
								}
								e.preventDefault();
								e.stopPropagation();
							}}
							onDrop={(e) => {
								e.preventDefault();
								e.stopPropagation();
								if (e.dataTransfer.items) {
									for (let i = 0; i < e.dataTransfer.items.length; i++) {
										if (e.dataTransfer.items[i].kind === 'file') {
											const file = e.dataTransfer.items[i].getAsFile();
											handleFileDrop(file);
										}
									}
								} else {
									for (let i = 0; i < e.dataTransfer.files.length; i++) {
										handleFileDrop(e.dataTransfer.files[i]);
									}
								}
							}}
						>
							<input
								type="file"
								style={{ display: 'none' }}
								ref={fileInputRef}
								onChange={handleFileChange}
								disabled={outOfCredit()}
								accept=".txt, .jpg, .jpeg, .png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
							/>

							<span class="d-inline-block theme-color mb-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
									<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
									<path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
								</svg>
							</span>
							<p class="theme-color mb-0">{t("Drop File Here")}</p>
						</div>
						<div class="text-center">
							<button class="btn theme-btn mt-2" onClick={() => {
								if (outOfCredit()) {
									showModal("UPGRADE-UPLOAD");
									return;
								}

								fileInputRef.current.click();
							}}>
								<span class="btn-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
										<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
									</svg>
								</span>{t("Browse")}
							</button>
						</div>
					</div>

					:
					<div class="card card-padding text-center justify-content-between ">


						<div class="form-group" >
							<p class="ft-18-title mb-2">{t("Type/Paste")}</p>
							<textarea style={{ overflowY: 'auto' }} value={typedText2} onChange={(e) => setTypedText2(e.target.value)} rows="4" class="form-control round-input type-file " placeholder={t("Type or paste your content here and talk with that...")}></textarea>
						</div>
						<div class="text-center">
							<button class="btn theme-btn " onClick={(e) => {
								if (outOfCredit()) {
									showModal("UPGRADE-UPLOAD");
									return;
								}
								lang_picker(typedText2)
							}}
								disabled={typedText2.length > 0 ? false : true}
							>{t("Submit")} </button>
						</div>

					</div>}

			</div>
			{/*<div class="col-lg-4 card-margin" style={{ display: isLoading || urlUpload ? "none" : "" }}>
				<div class="card card-100 flex-center card-padding justify-content-between" >

					<p class="ft-18-title mb-2">{t("Paste a Web Link")}</p>

					<div class="form-group input-icon-div w-100 text-center mb-0">
						<p>{t("Enter the website's URL and talk with it")}</p>
						<input  onChange={onChangeUrl} type="text" class="form-control round-input" placeholder={`${t("Write URL here...")}`} name="" value={webURL} />
						<span class="input-icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
								<path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
							</svg>
						</span>
					</div>
					<div style={{ color: "red", marginTop: '0px' }}>{t(`${urlWarning ?? ''}`)}</div>
					<div class="text-center">
						<button onClick={(e) => {
							if (outOfCredit()) {
								showModal("UPGRADE-UPLOAD");
								return;
							}
							uploadByWebLink(webURL)
						}} class="btn theme-btn mt-3"
							disabled={urlWarning === '' ? false : true}
						>{t('Upload')}</button>
					</div>
				</div>
					</div> */}


		</>
	);
}

export default ImportFile;


// s3 upload function for files smaller than 5 mb
export const uploadToS3 = async (presignedUrl, file, onUploadProgress) => {
	try {
		const result = await axios.put(presignedUrl, file, {
			headers: {
				'Content-Type': file.type,
			},
			onUploadProgress: progressEvent => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				onUploadProgress(percentCompleted);
			}
		});
		return result;
	} catch (error) {
		throw error;
	}
};


