import React, { useRef, useEffect, useState } from 'react';
import { useParams, Link, useLocation } from "react-router-dom";

import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.css';
import '../../assets/css/custome.css';
import '../../assets/css/responsive.css';

import { useAuth } from '../../Auth/AuthContext';
import { useNavigate } from 'react-router-dom';

import rename from "../../assets/img/icon/rename.svg";
import fileMark from '../../assets/img/files/file-mark.svg';
import undo from '../../assets/img/files/undo.svg';
import redo from '../../assets/img/files/redo.svg';
import share from '../../assets/img/files/share.svg';
import { fetchData, fetchDataPost } from "../../hooks/apiUtils";
import { showToast } from "../../components/common/toast";
import { useModal } from "../../components/common/Modal/ModalContext";
import logoAmigotor from '../../assets/img/logo_white_nobuffer.png';
import logoTranskriptor from '../../assets/img/logo-transkriptor-nobuffer.png';
import AiChat from '../Editor/AiChat';
import { useTranslation } from '../../components/common/LanguageProvider';
import logoRecorditor from '../../assets/img/logo_recorditor.png';
import logoMeetingtor from '../../assets/img/logo_meetingtor.png';

function ChatSingleView({ handleOpenSidebar }) {
	const { fid, oid } = useParams();

	const { userData, hashedId } = useAuth();
	const { t } = useTranslation();
	const [userStatus, setUserStatus] = useState(null);
	const [routeLoading, setRouteLoading] = useState(false);
	const [date, setDate] = useState(null);
	const [sessionName, setSessionName] = useState(null);
	const [isAnswerLoading, setIsAnswerLoading] = useState(false);
	const [transkriptorOrderId, setTranskriptorOrderId] = useState(null)
	const [TranskriptorSK, setTranskriptorSK] = useState(null)
	const [orderPk, setOrderPk] = useState(null)
	const [recorditorSK, setRecorditorSK] = useState(null)
	const [meetingtorSk, setMeetingtorSk] = useState(null)
	const { showModal } = useModal();


	useEffect(() => {
		shareLink();
	}, []);


	useEffect(() => {

		if (oid && hashedId)
			getOrderInfo(oid === hashedId);
	}, [
		oid, hashedId
	]);
	useEffect(() => {
		if (userData)
			setUserStatus(userData.SStatus);
	}, [userData]);





	const isMobileDevice = () => {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	};



	const onRename = async (tid, uid, name) => {
		setSessionName(name);
		const response = await fetchDataPost(
			"https://v91m7qwz82.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFile?",
			{ tid: "#AmigotorSession#" + tid, Tname: name, cid: "User#" + uid }
		);
		if (response)
			showToast(t("File renamed"), { type: "success" });
	};

	const shareLink = async () => {
		try {
			// Get the current origin (e.g., "http://localhost:3000" or "https://app.transkriptor.com")
			let baseUrl = window.location.origin;

			let link = baseUrl + "/chat/" + oid;

			await navigator.clipboard.writeText(link);

			showToast(t("File link copied to clipboard"));

			return true;

		} catch (err) {
			console.error('Failed to copy text: ', err);
			return false;
		}
	};


	const getOrderInfo = async (globalSesh) => {
		if (globalSesh) {
			setSessionName(t("Talk With All Files"));
			setDate(null);
			setRouteLoading(false);
			return;
		}
		else {
			setRouteLoading(false);
		}
	};




	useEffect(() => {
		const handleCopy = (e) => {
			if (userStatus && userStatus === "Trial") {
				e.preventDefault();
				showModal("UPGRADE-DOWNLOAD");
			}
		};

		document.addEventListener('copy', handleCopy);

		return () => {
			document.removeEventListener('copy', handleCopy); // Cleanup the event listener on component unmount
		};
	}, [userStatus]);




	return (

		<div className="container-fluid p-0">
			<div className="row justify-content-center">
				<div className="col-12">
					<div className="card card-padding overflow-visible">
						<div className="row align-items-center">
							<div className="col-xxl-4 col-lg-4 col-md-4 col-12 mb-2 order-xxl-1 order-lg-1 order-md-1 order-1 text-md-start text-center notranslate">
								<h2 class="small-title regular mb-2">
									<span class="me-2 burger-web-view">
										<img
											src={fileMark}
											class="img-fluid"
											height="auto"
											width="28px"
											alt="img"
										/>
									</span>

									<span class="me-2 burger-mobile-view" onClick={() => handleOpenSidebar()}>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-black" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 12H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 6H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 18H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>

									</span>
									<div className="filename-container">
										<span className="filename">{sessionName}</span>
										{oid !== hashedId &&
											<Link
												onClick={() =>
													showModal(
														"RENAME",
														{ name: sessionName, tid: oid, uid: hashedId },
														{ onRename }
													)
												}
												className="web-view"
											>
												<span
													class="icons-hover"
													data-bs-toggle="tooltip"
													data-bs-placement="bottom"
													title={t("Rename")}
													data-bs-original-title="Rename"
												>
													<img src={rename} class="img-fluid" alt="img" />
												</span>
											</Link>
										}


									</div>

								</h2>

								{(date) &&
									<p class="d-sm-inline-block d-block ft-14 mb-1 me-sm-4 me-0 web-view">
										<span class="me-1">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="16"
												viewBox="0 0 19.2 18"
											>
												<g id="date-line" transform="translate(-2 -2)">
													<path
														id="Path_135"
														data-name="Path 135"
														d="M20.15,6H18.2V7.2H20V20.4H3.2V7.2H5V6H3.05A1.068,1.068,0,0,0,2,7.086V20.514A1.068,1.068,0,0,0,3.05,21.6h17.1a1.068,1.068,0,0,0,1.05-1.086V7.086A1.068,1.068,0,0,0,20.15,6Z"
														transform="translate(0 -1.6)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_136"
														data-name="Path 136"
														d="M8,14H9.2v1.2H8Z"
														transform="translate(-2.4 -4.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_137"
														data-name="Path 137"
														d="M14,14h1.2v1.2H14Z"
														transform="translate(-4.8 -4.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_138"
														data-name="Path 138"
														d="M20,14h1.2v1.2H20Z"
														transform="translate(-7.2 -4.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_139"
														data-name="Path 139"
														d="M26,14h1.2v1.2H26Z"
														transform="translate(-9.6 -4.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_140"
														data-name="Path 140"
														d="M8,19H9.2v1.2H8Z"
														transform="translate(-2.4 -6.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_141"
														data-name="Path 141"
														d="M14,19h1.2v1.2H14Z"
														transform="translate(-4.8 -6.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_142"
														data-name="Path 142"
														d="M20,19h1.2v1.2H20Z"
														transform="translate(-7.2 -6.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_143"
														data-name="Path 143"
														d="M26,19h1.2v1.2H26Z"
														transform="translate(-9.6 -6.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_144"
														data-name="Path 144"
														d="M8,24H9.2v1.2H8Z"
														transform="translate(-2.4 -8.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_145"
														data-name="Path 145"
														d="M14,24h1.2v1.2H14Z"
														transform="translate(-4.8 -8.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_146"
														data-name="Path 146"
														d="M20,24h1.2v1.2H20Z"
														transform="translate(-7.2 -8.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_147"
														data-name="Path 147"
														d="M26,24h1.2v1.2H26Z"
														transform="translate(-9.6 -8.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_148"
														data-name="Path 148"
														d="M9.6,6.8a.6.6,0,0,0,.6-.6V2.6A.6.6,0,0,0,9,2.6V6.2A.6.6,0,0,0,9.6,6.8Z"
														transform="translate(-2.8)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_149"
														data-name="Path 149"
														d="M25.6,6.8a.6.6,0,0,0,.6-.6V2.6a.6.6,0,0,0-1.2,0V6.2A.6.6,0,0,0,25.6,6.8Z"
														transform="translate(-9.2)"
														fill="#6b6c6d"
													/>
													<path
														id="Path_150"
														data-name="Path 150"
														d="M13,6h6V7.2H13Z"
														transform="translate(-4.4 -1.6)"
														fill="#6b6c6d"
													/>
												</g>
											</svg>
										</span>
										{date}
									</p>}

							</div>
							<div class="col-xxl-5 col-lg-4 col-md-4 col-12 order-xxl-2 order-lg-2 order-md-3 order-3">
							</div>
							<div class="col-xxl-3 col-lg-4 col-md-4 col-12 order-xxl-3 order-lg-3 order-md-2 order-2 text-md-end text-center notranslate">

								<div class="file-action-icon-flex mb-3">



									<Link class="share_btn hover-effect" onClick={(e) => {
										e.preventDefault();
										shareLink()
									}}>
										<span
											class="file-action-list"
										>
											<span className="hover-span">
												{t("Share")}
											</span>
											<img src={share} class="img-fluid" alt="img" />
										</span>
									</Link>
								</div>
							</div>
						</div>
						<hr class="mt-0" />
						<div >
							<ul
								class="nav nav-pills btn-pills mb-3 notranslate"
								id="pills-tab"
								role="tablist"
							>
								<li class="nav-item" role="presentation">
									<button
										class="nav-link active"
										id="pills-traskript-tab"
										data-bs-toggle="pill"
										data-bs-target="#pills-traskript"
										type="button"
										role="tab"
										aria-controls="pills-traskript"
										aria-selected="true"
									>
										<span class="btn-tab-icon" >
											<img
												src=''
												class="img-fluid btn-reg-icon"
												alt="img"
											/>
											<img
												src={logoAmigotor}
												class="img-fluid btn-active-icon"
												alt="img"
											/>
										</span>

									</button>
								</li>
								{transkriptorOrderId && TranskriptorSK && orderPk &&
									<li class="nav-item" role="presentation">
										<button
											class="nav-link meetingtor-tab"
											type="button"
											role="tab"
											style={{ border: '1px solid #0174f3' }}
											onClick={() => {
												const url = `https://app.transkriptor.com/editor/${TranskriptorSK}/${orderPk}?uid=${hashedId}`
												window.open(url, '_blank');
											}}
										>
											<span class="btn-tab-icon me-1">
												<img
													src={logoTranskriptor}
													class="img-fluid btn-reg-icon"
													alt="img"
												/>

											</span>
										</button>
									</li>}
									{recorditorSK &&
												<li class="nav-item" role="presentation">
													<button
														class="nav-link meetingtor-tab"
														type="button"
														role="tab"
														style={{ border: '1px solid #FF3938' }}
														onClick={() => {
															const url = `https://app.recorditor.com/recording/${recorditorSK}/${orderPk}?uid=${hashedId}`
															window.open(url, '_blank');
														}}
													>
														<span class="btn-tab-icon me-1">
															<img
																src={logoRecorditor}
																class="img-fluid btn-reg-icon"
																alt="img"
															/>

														</span>
													</button>
												</li>}
												{meetingtorSk &&
												<li class="nav-item" role="presentation">
													<button
														class="nav-link meetingtor-tab"
														type="button"
														role="tab"
														style={{ border: '1px solid #00D13F' }}
														onClick={() => {
															const url = `https://app.meetingtor.com/meeting/${meetingtorSk}/${orderPk}?uid=${hashedId}`
															window.open(url, '_blank');
														}}
													>
														<span class="btn-tab-icon me-1">
															<img
																src={logoMeetingtor}
																class="img-fluid btn-reg-icon"
																alt="img"
															/>

														</span>
													</button>
												</li>}
							</ul>
							<div class="tab-content" id="pills-tabContent">
								<div
									class="tab-pane fade show active"
									id="pills-traskript"
									role="tabpanel"
									aria-labelledby="pills-traskript-tab"
								>
									{(routeLoading) &&
										<div className=' card-padding'>
											<br />
											<div className='d-flex justify-content-center text-center'>
												<span class="spinner-border text-primary"></span>
											</div>
										</div>
									}
									<div>
										<AiChat
											checkedItems={[]}
											amigotorOid={oid} singleView={true}
											setSessionName={setSessionName}
											setDate={setDate}
											isAnswerLoading={isAnswerLoading}
											setIsAnswerLoading={setIsAnswerLoading}
											setTranskriptorOrderId={setTranskriptorOrderId}
											setTranskriptorSK={setTranskriptorSK}
											setMeetingtorSk={setMeetingtorSk}
											setRecorditorSK={setRecorditorSK}
											setOrderPk={setOrderPk}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
}

export default ChatSingleView;