import React, { useEffect, useState } from 'react';
import { useApiData } from '../../hooks/useApiData';
import { useAuth } from '../../Auth/AuthContext';

import addFolderSvg from '../../assets/img/icon/add-folder.svg';
import upgradeSvg from '../../assets/img/icon/upgrade.svg';

import { useNavigate, Link, useParams } from 'react-router-dom';

import { useModal } from '../../components/common/Modal/ModalContext'
import { fetchData, fetchDataPost } from '../../hooks/apiUtils';
import FoldersItem from './FoldersItem';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { showToast } from '../../components/common/toast';
import { useTranslation } from '../../components/common/LanguageProvider';
function Folders(props) {
	const { t } = useTranslation();
	const { tid, uid } = useParams();
	const { hashedId, userData } = useAuth();
	const navigate = useNavigate();
	const { showModal } = useModal();
	const [userStatus, setUserStatus] = useState(null);

	const [isLoading, setIsLoading] = useState(true);
	const [allFolders, setAllFolders] = useState([]);
	const [foldersToShow, setFoldersToShow] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState("");
	const isFolder = props.isFolder === false ? props.isFolder : true;
	const foldersPerPage = 10;
	var startIndex = 0;
	var endIndex = 0;

	const showFolders = async () => {
		setIsLoading(true);
		let tempId = tid ? tid : hashedId;
		const response = await fetchData("https://i3w4h1tgb5.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFolders?cid=" + tempId);
		setAllFolders(response);
		setIsLoading(false);
	};
	useEffect(() => {
		if (userData)
			setUserStatus(userData.SStatus);
	}, [userData]);
	useEffect(() => {
		showFolders();
	}, [hashedId]);
	useEffect(() => {
		// Calculate the indexes of files to be displayed on the current page
		startIndex = (currentPage - 1) * foldersPerPage;
		endIndex = startIndex + foldersPerPage;

		// Update the files to show based on the new indexes
		setFoldersToShow(allFolders.slice(startIndex, endIndex));

	}, [allFolders, currentPage]);
	const onCreateFolder = async (uid, folderName) => {
		setIsLoading(true);
		const response = await fetchDataPost("https://lq3fr34z20.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreateFolder",
			{ Fname: folderName, cid: uid });
		showFolders();
		showToast(t("Folder created"));
	};
	const handleDelete = (fid, uid) => {
		console.log(fid, uid);

		const response = fetchDataPost("https://5ex4m1gaw1.execute-api.eu-central-1.amazonaws.com/default/AA-API-DeleteFolder?cid=" + uid + "&tid=" + fid);
		const updatedFolders = allFolders.filter(folder => folder.SK.S.replace("User#", "") !== fid);
		setAllFolders(updatedFolders);
		showToast(t("Folder deleted"));
	};
	const handleRename = (fid, uid, name) => {
		console.log(name, fid, uid);

		const response = fetchDataPost("https://xc8v5q5vc2.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFolder",
			{ "tid": fid, "Tname": name, "cid": uid });
		var updatedFolder = [...allFolders];
		updatedFolder.map(folder => {
			if (folder.SK.S.replace("User#", "") === fid) {
				folder.Fname.S = name;
			}
		});
		setAllFolders(updatedFolder);
		showToast(t("Folder renamed"));
	};


	const handleInputChange = (e) => {
		setSearchQuery(e.target.value);
		console.log(e.target.value);
		filterFolders(e.target.value);

	}

	const showCreateFolderModal = () => {
		if (userStatus && userStatus === "Trial") {
			showModal("UPGRADE-FOLDER");
		} else {
			showModal('CREATE-FOLDER', { uid: tid ? tid : hashedId }, { onCreateFolder });
		}
	}

	const filterFolders = (query) => {
		const filtered = allFolders.filter(folder =>
			folder?.Fname?.S.toLowerCase().includes(query.toLowerCase())
		);
		startIndex = (currentPage - 1) * foldersPerPage;
		endIndex = startIndex + foldersPerPage;
		setFoldersToShow(filtered.slice(startIndex, endIndex));
	}
	return (
		<>
			<div className='row'>
				<div className='col-lg-8 col-12' style={{ display: isFolder ? "" : "none" }}>
					<div className='d-lg-flex d-block justify-content-between align-items-center'>
						<h2 class="card-title mb-lg-4 mb-2">{t("Folders")}</h2>
						<div class="mb-4 table-serach input-icon-div d-lg-block d-none ms-auto">
							<input type="text" class="form-control round-input" name="" placeholder={t("Search...")} value={searchQuery} onChange={handleInputChange} />
							<span class="input-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
									<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
								</svg>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class={`col-lg-${!isFolder ? '12' : '8'} card-margin order-lg-1 order-2`}>
					<div class={`${!isFolder ? '' : ' d-lg-none'} d-block text-end mb-2`}>

						<button class="btn theme-btn mb-1" onClick={showCreateFolderModal} >
							<span class="btn-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
									<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
								</svg>
							</span>{t("Create").toUpperCase()}
						</button>
					</div>
					<div className='card card-padding h-100'>
					{isLoading ? (
						<div >
							<Skeleton count={10} height={30} style={{ borderRadius: '10px', marginTop: "10px" }} />

						</div>
						) :
						(foldersToShow.length === 0 ? (
				 
							<>
								<div class="col-12 card-margin">
									<div class="card card-padding h-100" onClick={showCreateFolderModal}>
										<div class="text-center">
											<p className='m-2'>{t("There is no folder yet, please create a folder to add files...")}</p>
											<img src={addFolderSvg} class="img-fluid mb-3" alt="icon" /><br></br>
											<p class="ft-18-title mb-2">{t("Create New Folder")}</p>
											<p>{t("Create folders and arrange your files as your needs.")}</p>
										</div>
										<div class="text-center">
											<button class="btn theme-btn" >
												<span class="btn-icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
														<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
														<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
													</svg>
												</span>{t("Create").toUpperCase()}
											</button>
										</div>
									</div>
								</div>
							</>
						) : (
							<>

								<div className=''>
									<table className='table'>
										<tbody>
											{foldersToShow.map((folder, index) => (
												<FoldersItem
													key={index}
													folder={folder}
													onDelete={handleDelete}
													onRename={handleRename} />
											))}
										</tbody>
									</table>
								</div>
								<nav aria-label="..." className={`${foldersToShow.length < foldersPerPage ? 'd-none' : ''}`} >
									<ul className='pagination pagination-sm justify-content-end'>
										<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
											<Link className="page-link" to="#" tabIndex="-1" aria-disabled="true" onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>
												<span className="fa fa-angle-left"></span>
											</Link>
										</li>

										{/* Show previous page if it's not the first page */}
										{currentPage > 1 && (
											<li className="page-item">
												<Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage - 1)}>
													{currentPage - 1}
												</Link>
											</li>
										)}

										{/* Current Page */}
										<li className="page-item active">
											<Link className="page-link" to="#" onClick={(e) => e.preventDefault()}>
												{currentPage}
											</Link>
										</li>

										{/* Show next page if it's not the last page */}
										{currentPage < Math.ceil(allFolders.length / foldersPerPage) && (
											<li className="page-item">
												<Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage + 1)}>
													{currentPage + 1}
												</Link>
											</li>
										)}

										<li className={`page-item ${currentPage === Math.ceil(allFolders.length / foldersPerPage) ? 'disabled' : ''}`}>
											<Link className="page-link" to="#" onClick={() => setCurrentPage(Math.min(currentPage + 1, Math.ceil(allFolders.length / foldersPerPage)))}>
												<span className="fa fa-angle-right"></span>
											</Link>
										</li>
									</ul>
								</nav>
							</>
							))
						}
					</div>
				</div>
				<div class="col-lg-4 order-lg-2 order-1 d-lg-block d-none" >
					<div class="row h-100" style={{ display: isFolder ? "" : "none" }}>
						<div class="col-12 card-margin">
							<div class="card card-padding h-100 flex-center card-hover" onClick={showCreateFolderModal}>
								<div class="text-center">
									<img src={addFolderSvg} class="img-fluid mb-3" alt="icon" /><br></br>
									<p class="ft-18-title mb-2">{t("Create New Folder")}</p>
									<p>{t("Create folders and arrange your files as your needs.")}</p>
								</div>

							</div>
						</div>
						<div class="col-12 card-margin">
							<a href="/payment">
							<div class="card card-padding h-100 flex-center card-hover" onClick={(e) => 
								{e.preventDefault();
								navigate("/payment")
							}}>
								<div class="text-center">
									<img src={upgradeSvg} class="img-fluid mb-3" alt="icon" /><br></br>
									<p class="ft-18-title mb-2">{t("Upgrade")}
										<span class="ms-1">

										</span>
									</p>
									<p>{t("Upgrade your account and enjoy the additional features")}</p>
								</div>
							</div>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="d-lg-none d-block text-end mb-2" style={{ marginTop: '40px' }}>
				<div class="card card-padding h-100 flex-center card-hover" onClick={() => navigate("/payment")}>
					<div class="text-center">
						<img src={upgradeSvg} class="img-fluid mb-3" alt="icon" /><br></br>
						<p class="ft-18-title mb-2">{t("Upgrade")}
							<span class="ms-1">

							</span>
						</p>
						<p>{t("Upgrade your account to talk to more files")}</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default Folders;
